import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import centroid from "@turf/centroid";
import { Geometry, feature, featureCollection } from "@turf/helpers";
import axios from "axios";
import {
  ATTENTION_POLYGON_LAYER,
  ATTENTION_WELL_LAYER,
  FACILITY_LABEL_LAYER,
  FACILITY_LAYER,
  SELECTED_SINGLE_WELL_LAYER,
  SELECTED_WELL_LAYER,
  SELECTED_WELL_POINT_LAYER,
  WELL_LABEL_LAYER,
  WELL_LAYER,
  WELL_LAYER_POINT
} from "constants/mapLayers.constants";
import _debounce from "lodash/debounce";
//don't transpile mapbox see https://docs.mapbox.com/mapbox-gl-js/guides/install/#transpiling
import mapboxglx, {
  GeoJSONSource,
  LngLatBoundsLike,
  LngLatLike,
  PointLike
} from "mapbox-gl";
import RulerControl from "mapbox-gl-controls/lib/ruler";
import FreeDraw from "mapbox-gl-draw-freehand-mode";
import { fromEvent, merge } from "rxjs";
import { debounceTime, map as maprx } from "rxjs/operators";
import { setMapIdleTime } from "store/features/dataTable/dataTableSlice";
import {
  updateHoverLegendGroup,
  updateHoverLegendItem
} from "store/features/groupBy/groupBySlice";
import {
  ISelectedWell,
  setAttentionWells,
  setMapExtent,
  setMapState,
  setSelectedWells
} from "store/features/map/mapSlice";
import { RootState } from "store/rootReducer";
import { removeCount } from "utils/removeCountFromTitle";
import { removeProbitInfoFromtitle } from "utils/removeProbitInfoFromTitle";

import { fetchEntitiesOnMap } from "api/map";

import { useDashboardContext } from "components/dashboard/hooks/useDashboardContext";
import useManageMapboxShapefileLoading from "components/map/hooks/manage-mapbox-shapefile-loading/useManageMapboxShapefileLoading";

import useBetaFeatures from "../../hooks/useBetaFeatures";
import useUserModules from "../../hooks/useUserModules";
import { EntityKind } from "../../models/entityKind";
import View3DControl from "./View3DControl";
import { EntitiesInGroupModel, HighlightedFeatures } from "./contexts/MapContextState";
import { useManageMapboxLayerSorting, useManageMapboxLayerStyles } from "./hooks";
import {
  defaultWellLineThickness,
  getMapLayerSources,
  getMapLayers
} from "./hooks/getMapLayers";
import useDataAccess from "./hooks/useDataAccess";
import useDataSouceAvailability from "./hooks/useDataSourceAvailability";
import { useMapContext } from "./hooks/useMapContext";
import { useMapDispatch } from "./hooks/useMapDispatch";
import DrawCircle from "./modes/DrawCircle";
import DrawRectangle from "./modes/DrawRectangle";
import { waitForStyleToLoad } from "./utils";
import { polygonStyles } from "./utils/drawStyles";
import { emptyFeatureCollection } from "./utils/emptyFeatureCollection";
import { findEntitiesFromGroup } from "./utils/findEntitiesFromGroup";
import { getSingleWell } from "./utils/getSingleWell";
import DrawTwoPointLineString from "./utils/two_point_line";

export interface MapboxWrapperComponentModel {
  addLayer;
  drawRef: MutableRefObject<MapboxDraw>;
  id: string;
  mapboxRef: MutableRefObject<mapboxglx.Map>;
  removeLayer;
  setLayers;
}

const clearSelectedWells = (mapbox: mapboxgl) => {
  if (!mapbox) return;
  const source = mapbox.getSource(SELECTED_WELL_LAYER) as mapboxgl.GeoJSONSource;

  source?.setData({ ...emptyFeatureCollection });
  const pointSource = mapbox.getSource(SELECTED_WELL_LAYER) as mapboxgl.GeoJSONSource;

  source?.setData({ ...emptyFeatureCollection });
  pointSource?.setData({ ...emptyFeatureCollection });
};

const mapServiceEndpoint = process.env.REACT_APP_MAP_SERVICE;
const geoMapServiceEndpoint = process.env.REACT_APP_GEO_MAP_SERVICE;

export default function MapboxWrapper({
  addLayer,
  drawRef,
  id,
  mapboxRef,
  removeLayer,
  setLayers
}: MapboxWrapperComponentModel): JSX.Element {
  const dispatch = useDispatch();
  const activeEntityKinds = useSelector(
    (state: RootState) => state.app.activeEntityKinds
  );
  const { hasFeature } = useBetaFeatures();
  // global
  // auth
  const orgId = useSelector((state: RootState) => state.auth.user.organization?.id);
  const token = useSelector((state: RootState) => state.auth.jwtToken);

  // filter
  const isFiltering = useSelector((state: RootState) => state.filter.isFiltering);
  const wellListFilter = useSelector((state: RootState) => state.filter.wellListFilter);
  const wellListFilterExtent = useSelector(
    (state: RootState) => state.filter.wellListFilterExtent
  );

  // groupBy
  const hoverLegendItem = useSelector(
    (state: RootState) => state.groupBy.hoverLegendItem
  );
  const selectedGroups = useSelector((state: RootState) => state.groupBy.selectedGroups);

  // map
  const attentionPolygon = useSelector((state: RootState) => state.map.attentionPolygon);
  const attentionWells = useSelector((state: RootState) => state.map.attentionWells);
  const flyToGroup = useSelector((state: RootState) => state.map.flyToGroup);
  const isViewLocked = useSelector((state: RootState) => state.map.viewLock);
  const layers = useSelector((state: RootState) => state.map.layers);
  const mapState = useSelector((state: RootState) => state.map.mapState);
  const selectedWells: { [name: string]: ISelectedWell } = useSelector(
    (state: RootState) => state.map.selectedWells
  );
  const txnId = useSelector((state: RootState) => state.map.txnId);

  // user
  const isDefaultMapSettingsLoaded = useSelector(
    (state: RootState) => state.userSetting.isDefaultSettingsLoaded
  );

  //context
  const { capabilities, lockMap } = useDashboardContext();
  const mapDispatch = useMapDispatch();
  const {
    entitiesInGroup,
    facilitiesInGroup,
    highlightedFeatures,
    isMapInitialized,
    isPolygonFilterControlVisible
  } = useMapContext();

  const activeEntities = useSelector((state: RootState) => state.app.activeEntityKinds);

  useEffect(() => {
    // Clear selected wells on map when wells are not active.
    const hasWellsOnMap = Object.keys(entitiesInGroup).length > 0;
    if (hasWellsOnMap && !activeEntities.includes(EntityKind.Well)) {
      dispatch(setSelectedWells({}));
    }
  }, [activeEntities]);

  // hooks
  const { has3dGeoModel } = useUserModules();
  const { hasSurvey } = useDataAccess();
  const { hasGdc, hasIhs } = useDataSouceAvailability();
  useHotkeys("esc", () => doCancel());

  // Layer & Shapefile management for mapbox
  useManageMapboxShapefileLoading({ addLayer: addLayer, removeLayer: removeLayer });
  useManageMapboxLayerSorting({ mapbox: mapboxRef?.current });
  useManageMapboxLayerStyles({ mapbox: mapboxRef?.current });

  // component state
  const [isInSingleDashboard, setIsInSingleDashboard] = useState(false);
  const [tileUri, setTileUri] = useState({
    hz: "",
    v: "",
    facility: ""
  });

  // refs
  const entitiesInGroupRef = useRef(entitiesInGroup);
  const isPolygonFilterControlVisibleRef = useRef(isPolygonFilterControlVisible);
  const mapId = useRef(id);
  const state = useRef({
    extentChanged: false,
    isIdle: false,
    styledata: true
  });
  const tileUriRef = useRef(tileUri);
  const tokenRef = useRef<string>(token);
  const txnIdRef = useRef(txnId);
  const viewLockedByDashboardRef = useRef(lockMap);
  const viewLockedRef = useRef(isViewLocked);

  // constants
  const hzTileUri = `${mapServiceEndpoint}/well/hz/${orgId}/{z}/{x}/{y}`;
  const veritcalTileUri = `${mapServiceEndpoint}/well/vert/${orgId}/{z}/{x}/{y}`;
  const facilityTileUri = `${mapServiceEndpoint}/well/facility/${orgId}/{z}/{x}/{y}`;
  const MAX_ZOOM_LEVEL_TO_SHOW_SELECTION = 7;

  const createMapbox = useCallback((): mapboxgl.Map => {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiaG9kYTk5IiwiYSI6ImNrMWxhcngydDAzcXMzbXA3dGs3Ym5vMjYifQ.Aao1Lz2YhOUIpJLi2ZplHw";

    const center: LngLatLike = mapState
      ? [mapState.lng, mapState.lat]
      : [-120.608666, 55.869737];
    const zoom = mapState ? mapState.zoom : 5;
    // Initialize Map
    const mapbox = new mapboxgl.Map({
      container: mapId.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: center,
      zoom: zoom,
      preserveDrawingBuffer: true,
      transformRequest: (url) => {
        const localRequest = url.startsWith(mapServiceEndpoint);
        const geoMapRequest = url.startsWith(geoMapServiceEndpoint);
        if (localRequest || geoMapRequest || url.includes("timeline")) {
          return {
            url: url,
            headers: {
              Authorization: "Bearer " + tokenRef.current
            }
          };
        }
      }
    }) as mapboxgl.Map;
    mapbox.on("load", () => {
      mapbox.loadImage("/static/factory-custom.png", (error, image) => {
        if (error) throw error;
        mapbox.addImage("gas-plant", image, { sdf: true });
      });

      mapbox.loadImage("/static/compressor-station.png", (error, image) => {
        if (error) throw error;
        mapbox.addImage("compressor-station", image, { sdf: true });
      });

      mapbox.loadImage("/static/battery.png", (error, image) => {
        if (error) throw error;
        mapbox.addImage("battery", image, { sdf: true });
      });

      mapbox.loadImage("/static/injection-disposal-facility.png", (error, image) => {
        if (error) throw error;
        mapbox.addImage("injection-facility", image, { sdf: true });
      });

      mapbox.loadImage("/static/gathering-system.png", (error, image) => {
        if (error) throw error;
        mapbox.addImage("gathering-system", image, { sdf: true });
      });

      mapbox.loadImage("/static/meter-station.png", (error, image) => {
        if (error) throw error;
        mapbox.addImage("metering-station", image, { sdf: true });
      });

      mapbox.loadImage("/static/waste-plant.png", (error, image) => {
        if (error) throw error;
        mapbox.addImage("waste-plant", image, { sdf: true });
      });

      mapbox.loadImage("/static/default-facility.png", (error, image) => {
        if (error) throw error;
        mapbox.addImage("default-facility", image, { sdf: true });
      });
    });

    mapbox.addControl(new mapboxgl.NavigationControl({ showZoom: false }));
    mapbox.addControl(new mapboxgl.ScaleControl());

    mapbox.addControl(new View3DControl(), "top-right");

    const mapRuler = new RulerControl();
    mapbox.addControl(mapRuler, "top-right");
    mapRuler.button.setAttribute("title", "Ruler");

    return mapbox;
  }, [mapState]);

  const dispatchSelectedFeature = useCallback(
    (features: GeoJSON.Feature[]) => {
      mapDispatch({
        payload: {
          selectedFeatures: features
        }
      });
    },
    [mapDispatch]
  );

  const dispatchHighlightedFeatures = useCallback(
    async (color, entities: string[]) => {
      const mapbox = mapboxRef?.current as mapboxglx.Map;
      const isStyledLoaded = await waitForStyleToLoad(mapbox, 100);

      if (
        !isStyledLoaded ||
        entities.length > 90000 ||
        isPolygonFilterControlVisibleRef.current
      ) {
        return;
      }

      const highlightIds = {};

      for (const feat of entities) {
        highlightIds[feat] = true;
      }

      const features = mapbox.querySourceFeatures(WELL_LAYER, {
        sourceLayer: "eva-wells",
        filter: ["has", ["get", "Uwi"], ["literal", highlightIds]]
      });

      const pointFeatures = mapbox.querySourceFeatures(WELL_LAYER_POINT, {
        sourceLayer: "eva-wells",
        filter: [
          "all",
          ["has", ["get", "Uwi"], ["literal", highlightIds]],
          ["!=", ["get", "group"], "leg"]
        ]
      });
      const facilityFeatures = mapbox.querySourceFeatures(FACILITY_LAYER, {
        sourceLayer: "eva-facility",
        filter: ["has", ["get", "Uwi"], ["literal", highlightIds]]
      });

      for (const feat of features) {
        feat.properties = Object.assign(feat.properties, { color: color });
      }
      for (const feat of pointFeatures) {
        feat.properties = Object.assign(feat.properties, { color: color });
      }
      for (const feat of facilityFeatures) {
        feat.properties = Object.assign(feat.properties, { color: color });
      }
      const highlightedFeatures = {
        line: features,
        point: pointFeatures,
        facility: facilityFeatures
      };
      mapDispatch({
        payload: {
          highlightedFeatures
        }
      });
    },
    [mapDispatch, mapboxRef]
  );

  const doCancel = useCallback(() => {
    dispatchHighlightedFeatures("", []);
    dispatch(setSelectedWells({}));
    dispatchSelectedFeature([]);
    const mapbox = mapboxRef.current;
    if (!mapbox) {
      return;
    }
    clearSelectedWells(mapbox);
  }, [dispatch, dispatchHighlightedFeatures, dispatchSelectedFeature, mapboxRef]);

  async function selectSingleWell() {
    const map = mapboxRef.current;
    const selectedWells = {};
    const feature = await getSingleWell(map);
    if (!feature) {
      return;
    }
    const well = feature.properties;
    selectedWells[well.Uwi] = well;

    dispatch(setSelectedWells(selectedWells));
    dispatchSelectedFeature([feature]);
  }

  const getWellLayerLineThickness = useCallback(() => {
    return (
      layers.find((layer) => layer.id === WELL_LAYER)?.lineWidth ||
      defaultWellLineThickness
    );
  }, [layers]);

  const getHighlightedLineThickness = useCallback(() => {
    const lineThickness = getWellLayerLineThickness();
    // 10 is the maximum of the line width input
    return lineThickness >= 7 ? 10 : lineThickness + 3;
  }, [getWellLayerLineThickness]);

  const clearHighlightLayer = useCallback(async () => {
    const mapbox = mapboxRef.current;
    if (!mapbox?.getLayer || !mapbox?.getLayer || !mapbox?.getLayer(WELL_LAYER)) {
      return;
    }

    try {
      await waitForStyleToLoad(mapboxRef.current, 100);

      // Use setpaint for well layer instead of dedicated highlight layer
      mapbox.setPaintProperty(WELL_LAYER, "line-width", [
        "literal",
        getWellLayerLineThickness()
      ]);

      mapbox.setLayoutProperty(FACILITY_LAYER, "icon-size", [
        "interpolate",
        ["linear"],
        ["zoom"],
        6,
        0.2,
        10,
        1.0,
        12,
        1.5,
        22,
        2.0
      ]);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, [getWellLayerLineThickness, mapboxRef]);

  function filterToMapToEntities(
    groupEntities: { [p: string]: EntitiesInGroupModel },
    facilityEntities: { [p: string]: EntitiesInGroupModel },
    hasFacility: boolean,
    mapbox: mapboxgl.Map
  ) {
    if (!mapbox.getLayer(WELL_LAYER)) {
      return;
    }
    const ids = {};
    const facilityIds = {};
    //combine all the ids in each group
    for (const title of Object.keys(groupEntities)) {
      const group = groupEntities[title];

      for (const id of Object.keys(group.uwis)) {
        ids[id] = group.uwis[id];
      }
    }

    if (hasFacility) {
      for (const title of Object.keys(facilityEntities)) {
        const group = facilityEntities[title];

        for (const id of Object.keys(group.uwis)) {
          facilityIds[id] = group.uwis[id];
        }
      }
    }
    mapbox.setFilter(FACILITY_LAYER, ["has", ["get", "Uwi"], ["literal", facilityIds]]);
    mapbox.setFilter(WELL_LAYER, ["has", ["get", "Uwi"], ["literal", ids]]);
    mapbox.setFilter(WELL_LAYER_POINT, [
      "all",
      ["has", ["get", "Uwi"], ["literal", ids]],
      ["!=", ["get", "group"], "leg"]
    ]);
    if (!isMapInitialized) {
      mapbox.setPaintProperty(WELL_LAYER, "line-color", [
        "get",
        ["get", "Uwi"],
        ["literal", ids]
      ]);
      mapbox.setPaintProperty(WELL_LAYER_POINT, "circle-color", [
        "get",
        ["get", "Uwi"],
        ["literal", ids]
      ]);
      mapbox.setPaintProperty(FACILITY_LAYER, "icon-color", [
        "get",
        ["get", "Uwi"],
        ["literal", facilityIds]
      ]);
    } else {
      for (const id in ids) {
        const wellState = {
          color: ids[id]
        };
        mapbox.setFeatureState(
          {
            id,
            source: WELL_LAYER,
            sourceLayer: "eva-wells"
          },
          wellState
        );
        mapbox.setFeatureState(
          {
            id,
            source: WELL_LAYER_POINT,
            sourceLayer: "eva-wells"
          },
          wellState
        );
      }
      mapbox.setPaintProperty(FACILITY_LAYER, "icon-color", [
        "case",
        ["has", "color"],
        ["get", "color"],
        ["get", ["get", "Uwi"], ["literal", facilityIds]]
      ]);
      mapbox.setPaintProperty(WELL_LAYER, "line-color", [
        "case",
        ["has", "color"],
        ["get", "color"],
        ["feature-state", "color"]
      ]);
      mapbox.setPaintProperty(WELL_LAYER_POINT, "circle-color", [
        "case",
        ["has", "color"],
        ["get", "color"],
        ["feature-state", "color"]
      ]);
    }
  }

  const filterWellOnMap = useCallback(
    (
      txn,
      groupEntities: { [title: string]: EntitiesInGroupModel },
      facilityEntities: { [title: string]: EntitiesInGroupModel }
    ) => {
      const mapbox = mapboxRef.current;
      if ((!txn?.id && !txn?.facilityId) || !mapbox) {
        return;
      }
      const hasFacility =
        txn.facilityId !== "" && activeEntityKinds.includes(EntityKind.Facility);
      if (
        tileUriRef.current.hz !== hzTileUri ||
        tileUriRef.current.v !== veritcalTileUri ||
        tileUriRef.current.facility !== facilityTileUri
      ) {
        const uri = {
          hz: hzTileUri,
          v: veritcalTileUri,
          facility: facilityTileUri
        };
        updateWellAndFacilityTileUri(mapbox, uri, hasFacility);
        setTileUri(uri);
      }
      filterToMapToEntities(groupEntities, facilityEntities, hasFacility, mapbox);
    },
    [
      hzTileUri,
      isMapInitialized,
      activeEntityKinds,
      mapboxRef,
      facilityTileUri,
      veritcalTileUri
    ]
  );

  const canFilterWellsOnMap = (mapbox: mapboxgl.Map) => {
    const count =
      txnIdRef.current.legend.legendItems.length === 0
        ? 0
        : txnIdRef.current.legend.legendItems
            .map((li) => li.count)
            .reduce((acc, e) => acc + e);
    const zoom = mapbox.getZoom();
    return count < 120000 && zoom >= 6;
  };

  const updateLabels = useCallback(
    (id: string, layer: string) => {
      const mapbox = mapboxRef.current;
      if (!mapbox) {
        return;
      }
      axios
        .get(
          `${mapServiceEndpoint}/well/label/${Math.floor(mapbox.getZoom())}/${id}.json`
        )
        .then((response) => {
          if (
            !mapboxRef.current ||
            !response.data ||
            response.data.length === 0 ||
            response.data?.features.length === 0
          ) {
            return;
          }
          const data = response.data;
          const source = mapboxRef.current.getSource(layer) as mapboxgl.GeoJSONSource;
          if (source) {
            source.setData(data);
          } else {
            source.setData({ type: "FeatureCollection", features: [] });
          }
        });
    },
    [mapboxRef]
  );

  function updateWellAndFacilityTileUri(
    mapbox,
    uri: { hz: string; v: string; facility: string },
    hasFacility = false
  ) {
    const newStyle = mapbox.getStyle();
    if (newStyle.sources[WELL_LAYER]) {
      (newStyle.sources[WELL_LAYER] as mapboxgl.VectorSource).tiles = [uri.hz];
      (newStyle.sources[WELL_LAYER_POINT] as mapboxgl.VectorSource).tiles = [uri.v];
    }
    if (newStyle.sources[FACILITY_LAYER]) {
      if (hasFacility) {
        (newStyle.sources[FACILITY_LAYER] as mapboxgl.VectorSource).tiles = [
          uri.facility
        ];
      } else {
        //ensure we load the cleared map
        (newStyle.sources[FACILITY_LAYER] as mapboxgl.VectorSource).tiles = [
          `${mapServiceEndpoint}/well/facility/{z}/{x}/{y}?txn_id=clear`
        ];
      }
    }
    mapbox.setStyle(newStyle);
  }

  const updateWellsAndFacilitiesOnMap = useCallback(
    (wellId: string, facilityId: string) => {
      try {
        if (!wellId || !mapboxRef?.current || !mapboxRef.current.getLayer(WELL_LAYER)) {
          return;
        }
        const facilityActive = activeEntityKinds.includes(EntityKind.Facility);
        const uri = {
          hz: `${mapServiceEndpoint}/well/{z}/{x}/{y}.hz.mvt?txn_id=${wellId}`,
          v: `${mapServiceEndpoint}/well/{z}/{x}/{y}.vert.mvt?txn_id=${wellId}`,
          facility: `${mapServiceEndpoint}/well/facility/{z}/{x}/{y}?txn_id=${
            facilityActive ? facilityId : "clear"
          }`
        };
        const mapbox = mapboxRef?.current;
        updateWellAndFacilityTileUri(mapbox, uri, !!facilityId && facilityActive);

        mapbox.setFilter(WELL_LAYER, null);
        mapbox.setFilter(WELL_LAYER_POINT, null);
        mapbox.setFilter(FACILITY_LAYER, null);
        setTileUri(uri);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("error", error);
      }
    },
    [mapboxRef, activeEntityKinds]
  );

  const highlightDebounce = useMemo(() => {
    const highlightFeatures = (highlightedFeatures: HighlightedFeatures) => {
      const mapbox = mapboxRef.current;

      if (!mapbox?.getLayer(WELL_LAYER) || !mapbox.isStyleLoaded()) {
        return;
      }

      if (
        highlightedFeatures.line?.length > 0 ||
        highlightedFeatures.point?.length > 0 ||
        highlightedFeatures.facility?.length > 0
      ) {
        const data = {};
        const lineThickness = getWellLayerLineThickness();
        const highlightedLineThickness = getHighlightedLineThickness();

        for (const feature of highlightedFeatures.line) {
          data[feature.properties.Uwi] = highlightedLineThickness;
        }

        for (const feature of highlightedFeatures.point) {
          data[feature.properties.Uwi] = highlightedLineThickness;
        }
        for (const feature of highlightedFeatures.facility) {
          data[feature.properties.Uwi] = 2.0;
        }

        if (highlightedFeatures.line || highlightedFeatures.point) {
          // Use setpaint for well layer instead of dedicated highlight layer
          mapbox.setPaintProperty(WELL_LAYER, "line-width", [
            "case",
            ["has", ["get", "Uwi"], ["literal", data]],
            ["get", ["get", "Uwi"], ["literal", data]],
            ["literal", lineThickness]
          ]);
        }
        if (highlightedFeatures.facility.length > 0) {
          mapbox.setLayoutProperty(FACILITY_LAYER, "icon-size", [
            "interpolate",
            ["linear"],
            ["zoom"],
            6,
            [
              "case",
              ["has", ["get", "Uwi"], ["literal", data]],
              ["literal", 0.6],
              ["literal", 0.2]
            ],
            10,
            [
              "case",
              ["has", ["get", "Uwi"], ["literal", data]],
              ["literal", 1.8],
              ["literal", 1.0]
            ],
            12,
            [
              "case",
              ["has", ["get", "Uwi"], ["literal", data]],
              ["literal", 2.2],
              ["literal", 1.5]
            ],
            22,
            [
              "case",
              ["has", ["get", "Uwi"], ["literal", data]],
              ["literal", 3.0],
              ["literal", 2.0]
            ]
          ]);
        }
      }
    };

    return _debounce(highlightFeatures, 500);
  }, [getHighlightedLineThickness, getWellLayerLineThickness, mapboxRef]);

  const updateHoverItemDebounce = useCallback(
    (feature: GeoJSON.Feature) => {
      const mapbox = mapboxRef.current;
      if (!mapbox || !feature) {
        return;
      }

      const props = feature.properties;

      const uwi = props.Uwi;
      dispatch(setAttentionWells([uwi]));

      let group: string = props.group ?? "";

      for (const groupTitle of Object.keys(entitiesInGroupRef.current)) {
        const foundGroup = entitiesInGroupRef.current[groupTitle];
        if (foundGroup && foundGroup.uwis[uwi]) {
          group = foundGroup.group.title;
          break;
        }
      }

      dispatch(updateHoverLegendItem(group));
    },
    [dispatch, mapboxRef]
  );

  const onMouseMoveDebounce = useMemo(
    () => _debounce(updateHoverItemDebounce, 500),
    [updateHoverItemDebounce]
  );

  const onMouseLeave = useCallback(() => {
    if (!isPolygonFilterControlVisibleRef.current) {
      //only clear selected features if polygon filter control is not visible
      mapDispatch({
        payload: {
          selectedFeatures: [],
          highlightedFeatures: { line: [], point: [], facility: [] }
        }
      });
      dispatch(setAttentionWells([]));
      dispatch(updateHoverLegendItem(""));
      dispatch(updateHoverLegendGroup(""));
    }
    onMouseMoveDebounce.cancel();
  }, [dispatch, mapDispatch, onMouseMoveDebounce]);

  const onMouseMove = useCallback(
    (e) => {
      const mapbox = mapboxRef?.current;
      if (!mapbox) {
        return;
      }
      const buffer = 3;
      const bbox = [
        [e.point.x - buffer, e.point.y - buffer],
        [e.point.x + buffer, e.point.y + buffer]
      ] as [PointLike, PointLike];
      if (!mapbox.getLayer(WELL_LAYER)) {
        return;
      }
      const features = mapbox.queryRenderedFeatures(bbox, {
        layers: [WELL_LAYER, WELL_LAYER_POINT]
      });
      const entitiesInGroup = entitiesInGroupRef.current;
      if (!features || !features.length || !entitiesInGroup) {
        onMouseLeave();
        return;
      }
      onMouseMoveDebounce(features[0]);
    },
    [mapboxRef, onMouseLeave, onMouseMoveDebounce]
  );

  const addMapboxLayers = useCallback(() => {
    const mapbox = mapboxRef.current;

    if (!mapbox) return;
    const hasGeoModelLoader = hasFeature("Geo Model Loader");

    const sources = getMapLayerSources(has3dGeoModel, hasGeoModelLoader);
    for (const sourceId of Object.keys(sources)) {
      if (mapbox.getSource(sourceId)) {
        continue;
      }
      mapbox.addSource(sourceId, sources[sourceId]);
    }

    const layers = getMapLayers({
      hasSurvey,
      has3dGeoModel,
      hasGeoMap: hasGeoModelLoader,
      orgId,
      hasGdc,
      hasIhs
    });
    for (const layer of layers) {
      if (mapbox.getLayer(layer.id)) {
        continue;
      }
      addLayer(layer, layer.before);
    }

    if (!drawRef.current) {
      //add draw control after all the layers have been added
      //to ensure the draw is above those layers
      drawRef.current = new MapboxDraw({
        displayControlsDefault: false,
        userProperties: true,
        modes: Object.assign(MapboxDraw.modes, {
          draw_polygon: FreeDraw,
          two_point_line: DrawTwoPointLineString,
          draw_circle: DrawCircle,
          draw_rectangle: DrawRectangle
        }),
        styles: polygonStyles
      });

      mapbox.addControl(drawRef.current);
    }
  }, [addLayer, drawRef, has3dGeoModel, hasSurvey, mapboxRef]);

  const resetCursor = useCallback(() => {
    const mapbox = mapboxRef.current;
    if (!mapbox) {
      return;
    }
    mapbox.getCanvas().style.cursor = "default";
  }, [mapboxRef]);

  const onMapMove = useCallback(
    (mapbox) => {
      const bounds = mapbox.getBounds();

      const sw = bounds.getSouthWest();
      const ne = bounds.getNorthEast();
      const xmin = sw.lng;
      const ymin = sw.lat;
      const xmax = ne.lng;
      const ymax = ne.lat;
      const polygon: GeoJSON.Polygon = {
        type: "Polygon",
        coordinates: [
          [
            [xmin, ymin],
            [xmax, ymin],
            [xmax, ymax],
            [xmin, ymax],
            [xmin, ymin]
          ]
        ]
      };
      dispatch(setMapExtent(polygon));
      state.current = { extentChanged: true, isIdle: false, styledata: false };
      resetCursor();
    },
    [dispatch, resetCursor]
  );

  const initializeMap = useCallback(
    (mapbox: mapboxgl) => {
      if (!mapbox) {
        return;
      }
      const moveend$ = fromEvent(mapbox, "moveend");
      const movestart$ = fromEvent(mapbox, "movestart");
      const zoomstart$ = fromEvent(mapbox, "zoomstart");
      const zoomend$ = fromEvent(mapbox, "zoomend");
      const zoom$ = fromEvent(mapbox, "zoom");
      const viewLockCancel$ = fromEvent(mapbox, "viewLockCancel");
      merge(moveend$, movestart$, zoomstart$, zoomend$, zoom$, viewLockCancel$)
        .pipe(
          maprx((i) => i),
          debounceTime(700)
        )
        .subscribe(async () => {
          try {
            //notify bounds changed even if viewLocked so we can update the twp range grid
            mapDispatch({
              payload: {
                bounds: mapbox.getBounds()
              }
            });
            if (viewLockedRef.current || viewLockedByDashboardRef.current) {
              //don't trigger map move if view is locked
              return;
            }
            onMapMove(mapbox);
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
          }
        });
      if (!viewLockedByDashboardRef.current) {
        //dispatch the bounds have changed
        onMapMove(mapbox);
      }
      return mapbox;
    },
    [mapDispatch, onMapMove]
  );

  useEffect(() => {
    isPolygonFilterControlVisibleRef.current = isPolygonFilterControlVisible;
  }, [isPolygonFilterControlVisible]);

  useEffect(() => {
    viewLockedRef.current = isViewLocked;
  }, [isViewLocked]);

  useEffect(() => {
    entitiesInGroupRef.current = entitiesInGroup;
  }, [entitiesInGroup]);

  useEffect(() => {
    mapDispatch({
      payload: {
        isLoading: isFiltering
      }
    });
  }, [isFiltering, mapDispatch]);

  useEffect(() => {
    function showAttentionPolygon(attentionPolygon: GeoJSON.Polygon) {
      const mapbox = mapboxRef.current;
      if (!mapbox) {
        return;
      }
      if (!mapbox.getSource(ATTENTION_POLYGON_LAYER)) {
        mapbox.addSource(ATTENTION_POLYGON_LAYER, {
          data: { ...emptyFeatureCollection },
          type: "geojson"
        });
      }
      if (!mapbox.getLayer(ATTENTION_POLYGON_LAYER)) {
        addLayer({
          id: ATTENTION_POLYGON_LAYER,
          source: ATTENTION_POLYGON_LAYER,
          type: "line",
          paint: {
            "line-color": "black",
            "line-width": 4
          }
        });
      }
      const data = { ...emptyFeatureCollection };
      if (attentionPolygon) {
        data.features = [feature(attentionPolygon)];
      }
      (mapbox.getSource(ATTENTION_POLYGON_LAYER) as GeoJSONSource).setData(data);
    }

    showAttentionPolygon(attentionPolygon);
  }, [addLayer, attentionPolygon, mapboxRef]);

  useEffect(() => {
    if (capabilities) {
      setIsInSingleDashboard(capabilities.hasSingleWellWidget);
    }
  }, [capabilities]);

  useEffect(() => {
    viewLockedByDashboardRef.current = lockMap;
  }, [lockMap]);

  useEffect(() => {
    tokenRef.current = token;
  }, [token]);

  useEffect(() => {
    //watch txn id
    if (txnId.id) {
      txnIdRef.current = txnId;
    }
    //clear highlighted wells
    mapDispatch({
      payload: {
        highlightedFeatures: { line: [], point: [], facility: [] }
      }
    });

    if (!mapboxRef.current) {
      return;
    }
    const mapbox = mapboxRef.current;
    const source = mapbox.getSource(SELECTED_SINGLE_WELL_LAYER) as mapboxgl.GeoJSONSource;

    if (!source) {
      return;
    }

    source?.setData({ ...emptyFeatureCollection });

    if (
      Object.keys(selectedWells).length !== 1 ||
      (!capabilities.hasWellCardWidget &&
        !capabilities.hasSingleWellWidget &&
        !capabilities.hasMultiProductWidget)
    ) {
      return;
    }

    const wells = mapbox.querySourceFeatures(WELL_LAYER, {
      sourceLayer: "eva-wells",
      filter: ["==", ["get", "Uwi"], Object.keys(selectedWells)[0]]
    });

    const features = wells.map((feat) => feature(feat.geometry));
    const collection = featureCollection(features);
    source.setData(collection);
  }, [txnId, selectedWells, mapDispatch, capabilities]);

  useEffect(() => {
    if (!mapboxRef?.current || !isMapInitialized) {
      return;
    }

    tileUriRef.current = tileUri;
  }, [tileUri, isMapInitialized, mapboxRef]);

  useEffect(() => {
    if (wellListFilter) {
      const index = wellListFilter.findIndex(
        (filter) => filter !== null && filter.filterType === "well-list"
      );
      if (index >= 0) {
        doCancel();
      }
    }
  }, [wellListFilter]);

  useEffect(() => {
    if (!wellListFilterExtent || !mapboxRef.current) {
      return;
    }
    const mapbox = mapboxRef.current;
    mapbox.fitBounds(
      [
        [wellListFilterExtent.minx, wellListFilterExtent.miny],
        [wellListFilterExtent.maxx, wellListFilterExtent.maxy]
      ],
      { padding: 20, maxZoom: 12 }
    );
  }, [mapboxRef, wellListFilterExtent]);

  useEffect(() => {
    if (isInSingleDashboard) {
      selectSingleWell();
    } else {
      doCancel();
    }
  }, [isInSingleDashboard]);

  useEffect(() => {
    if (!isMapInitialized) {
      return;
    }

    function updateNoWellsOnMap(noWells) {
      //only show noWellsAvailable if clear did not trigger it
      mapDispatch({
        payload: {
          noWellsAvailable: noWells
        }
      });
    }

    async function updateMap() {
      let id = txnId.id;
      const mapbox = mapboxRef.current;
      if (!id) {
        id = "clear";
      }
      if (!id || !mapbox) {
        return;
      }

      function clearLabels(labelLayer: string) {
        if (mapbox.getLayer(labelLayer) && mapbox.getSource(labelLayer)) {
          (mapbox.getSource(labelLayer) as GeoJSONSource)?.setData({
            ...emptyFeatureCollection
          });
        }
      }

      if (id && id !== "clear") {
        updateLabels(id, WELL_LABEL_LAYER);
      } else {
        clearLabels(WELL_LABEL_LAYER);
      }
      if (txnId.facilityId && txnId.facilityId !== "clear") {
        updateLabels(txnId.facilityId, FACILITY_LABEL_LAYER);
      } else {
        clearLabels(FACILITY_LABEL_LAYER);
      }
      const groupEntities = await fetchEntitiesOnMap(id);
      const facilityEntities = await fetchEntitiesOnMap(txnId.facilityId);
      updateNoWellsOnMap(
        Object.keys(groupEntities).length === 0 &&
          activeEntityKinds.includes(EntityKind.Well)
      );
      mapDispatch({
        payload: {
          entitiesInGroup: groupEntities,
          facilitiesInGroup: facilityEntities
        }
      });
      if (canFilterWellsOnMap(mapbox)) {
        filterWellOnMap(txnId, groupEntities, facilityEntities);
      } else {
        updateWellsAndFacilitiesOnMap(id, txnId.facilityId);
      }
    }

    updateMap();
  }, [txnId, isMapInitialized, activeEntityKinds]);

  useEffect(() => {
    const mapbox = mapboxRef?.current;
    if (!mapbox || !mapbox.isStyleLoaded()) {
      return;
    }

    highlightedFeatures?.line?.length ||
    highlightedFeatures?.point?.length ||
    highlightedFeatures?.facility?.length
      ? highlightDebounce(highlightedFeatures)
      : clearHighlightLayer();
  }, [highlightedFeatures, mapboxRef]);

  useEffect(() => {
    if (
      !mapboxRef.current ||
      mapboxRef.current.getZoom() <= MAX_ZOOM_LEVEL_TO_SHOW_SELECTION
    ) {
      return;
    }

    async function highlightSelection() {
      let count = 0;
      while (mapboxRef.current && !mapboxRef.current.isStyleLoaded() && count < 10) {
        await new Promise((r) => setTimeout(r, 100));
        count++;
      }
      if (mapboxRef.current && !mapboxRef.current.isStyleLoaded()) {
        return;
      }
      const mapbox = mapboxRef.current;
      if (!mapbox) {
        return;
      }
      const selectedWellSource = mapbox.getSource(SELECTED_WELL_LAYER) as GeoJSONSource;
      if (!mapbox.getSource(SELECTED_WELL_POINT_LAYER)) {
        mapbox.addSource(SELECTED_WELL_POINT_LAYER, {
          type: "geojson",
          data: { ...emptyFeatureCollection }
        });
      }
      const selectedPointSource = mapbox.getSource(
        SELECTED_WELL_POINT_LAYER
      ) as GeoJSONSource;

      if (!selectedWellSource || !selectedPointSource || !entitiesInGroup) {
        return;
      }
      const highlightIds = {};
      for (const group of selectedGroups ?? []) {
        if (!entitiesInGroup[group]) {
          continue;
        }

        const uwis = entitiesInGroup[group].uwis;
        for (const uwi of Object.keys(uwis)) {
          highlightIds[uwi] = uwis[uwi];
        }
      }
      const features = mapbox.querySourceFeatures(WELL_LAYER, {
        sourceLayer: "eva-wells",
        filter: ["has", ["get", "Uwi"], ["literal", highlightIds]]
      });
      const facilityFeatures = mapbox.querySourceFeatures(FACILITY_LAYER, {
        sourceLayer: "eva-facility",
        filter: ["has", ["get", "Uwi"], ["literal", highlightIds]]
      });
      const pointFeatures = mapbox.querySourceFeatures(WELL_LAYER_POINT, {
        sourceLayer: "eva-wells",
        filter: [
          "all",
          ["has", ["get", "Uwi"], ["literal", highlightIds]],
          ["!=", ["get", "group"], "leg"]
        ]
      });
      const lineData = { ...emptyFeatureCollection };
      for (const feat of features) {
        feat.properties = {
          ...feat.properties,
          color: highlightIds[feat.properties.Uwi]
        };
      }
      for (const feat of pointFeatures) {
        feat.properties = {
          ...feat.properties,
          color: highlightIds[feat.properties.Uwi]
        };
      }

      for (const feat of facilityFeatures) {
        feat.properties = {
          ...feat.properties,
          color: highlightIds[feat.properties.Uwi]
        };
      }
      lineData.features = features;
      const pointData = { ...emptyFeatureCollection };
      pointData.features = pointFeatures;
      selectedWellSource.setData(lineData);
      selectedPointSource.setData(pointData);
    }

    highlightSelection();
  }, [mapboxRef, selectedGroups, entitiesInGroup]);

  useEffect(() => {
    if (!mapboxRef?.current || !entitiesInGroupRef.current) {
      return;
    }
    const mapbox = mapboxRef.current;
    const entitiesInGroup = entitiesInGroupRef.current;
    if (!(flyToGroup in entitiesInGroup)) {
      return;
    }
    const group = entitiesInGroup[flyToGroup];
    const features = mapbox.querySourceFeatures(WELL_LAYER, {
      sourceLayer: "eva-wells",
      filter: ["has", ["get", "Uwi"], ["literal", group.uwis]]
    });
    if (features.length === 0) {
      return;
    }
    let minx = Number.MAX_VALUE;
    let miny = Number.MAX_VALUE;
    let maxx = -Number.MAX_VALUE;
    let maxy = -Number.MAX_VALUE;
    for (const feat of features) {
      const point = centroid(feat.geometry as Geometry);
      if (!point) {
        continue;
      }
      const x = point.geometry.coordinates[0];
      const y = point.geometry.coordinates[1];
      minx = Math.min(minx, x);
      maxx = Math.max(maxx, x);
      miny = Math.min(miny, y);
      maxy = Math.max(maxy, y);
    }
    const bbox = [minx, miny, maxx, maxy] as LngLatBoundsLike;
    mapbox.fitBounds(bbox, { padding: 20 });
  }, [flyToGroup, mapboxRef]);

  useEffect(() => {
    async function onHover(
      item,
      entitiesInGroup: { [group: string]: EntitiesInGroupModel }
    ) {
      if (!mapboxRef.current) {
        return;
      }

      if (!mapboxRef.current.isStyleLoaded()) {
        await waitForStyleToLoad(mapboxRef.current, 100);
      }

      if (!item) {
        dispatchHighlightedFeatures("", []);
        return;
      }

      const itemTitle = removeCount(removeProbitInfoFromtitle(item));
      const groupKeys = Object.keys(entitiesInGroup);
      const index = groupKeys.findIndex((f) => removeCount(f) === itemTitle);

      let entities =
        entitiesInGroup[item] ||
        (index >= 0 ? entitiesInGroup[groupKeys[index]] : undefined);

      if (!entities) {
        entities =
          facilitiesInGroup[item] ||
          (index >= 0 ? facilitiesInGroup[groupKeys[index]] : undefined);
      }

      if (!entities) {
        const uwi = itemTitle.replaceAll("/", "").replaceAll("-", "");
        const items = findEntitiesFromGroup([uwi], entitiesInGroup);
        if (items && uwi in items) {
          entities = {
            uwis: { [uwi]: items[uwi].color },
            group: {
              id: 0,
              title: items[uwi].group,
              color: items[uwi].color
            }
          };
        }

        if (!entities) {
          return;
        }
      }
      const color = txnId.legend.legendItems.find((li) => li.title === itemTitle)?.color;
      dispatchHighlightedFeatures(color, Object.keys(entities.uwis));
    }

    onHover(hoverLegendItem, entitiesInGroup);
  }, [
    hoverLegendItem,
    entitiesInGroup,
    facilitiesInGroup,
    mapboxRef,
    txnId.legend.legendItems
  ]);

  useEffect(() => {
    /**
     * MAIN initialization mount/unmount
     * creates a new mapbox instance and attaches it to the dom
     * then waits until the style has been loaded and creates the layers
     * after all is done it will set the isMapInitialized state to true
     */

    if (!isDefaultMapSettingsLoaded) {
      return;
    }
    const mapbox = createMapbox();
    mapDispatch({
      payload: {
        isStyleLoaded: false,
        isMapInitialized: false
      }
    });
    mapDispatch({
      payload: {
        mapbox: mapbox
      }
    });
    mapboxRef.current = mapbox;
    const onStyleLoaded = () => {
      {
        //style load triggers whenever the base layer changes
        setLayers([]);
        mapDispatch({
          payload: {
            isStyleLoaded: true,
            isMapInitialized: false
          }
        });
        const waiting = () => {
          if (!mapbox.isStyleLoaded()) {
            setTimeout(waiting, 200);
          } else {
            addMapboxLayers();
            if (txnIdRef.current) {
              setTimeout(() => {
                tileUriRef.current = {
                  hz: "",
                  v: "",
                  facility: ""
                };
                //wait a second to allow layers to load before updating
                //wells on the layer
                if (canFilterWellsOnMap(mapbox)) {
                  filterWellOnMap(txnIdRef.current, entitiesInGroup, facilitiesInGroup);
                } else {
                  updateWellsAndFacilitiesOnMap(
                    txnIdRef.current.id,
                    txnIdRef.current.facilityId
                  );
                }
                updateLabels(txnIdRef.current.facilityId, FACILITY_LABEL_LAYER);
                updateLabels(txnIdRef.current.id, WELL_LABEL_LAYER);
                mapDispatch({
                  payload: {
                    isMapInitialized: true
                  }
                });
              }, 1000);
            }
          }
        };
        waiting();
      }
    };
    mapbox.on("style.load", onStyleLoaded);

    initializeMap(mapbox);
    mapDispatch({
      payload: {
        created: true
      }
    });

    const clearSelectedData = () => {
      if (mapbox?.getSource(SELECTED_WELL_LAYER)) {
        (mapbox.getSource(SELECTED_WELL_LAYER) as GeoJSONSource)?.setData({
          ...emptyFeatureCollection
        });
        (mapbox.getSource(SELECTED_WELL_POINT_LAYER) as GeoJSONSource)?.setData({
          ...emptyFeatureCollection
        });
      }
    };

    mapbox.on("zoomstart", clearSelectedData);
    mapbox.on("movestart", clearSelectedData);
    mapbox.on("styledata", () => {
      if (state.current.extentChanged) {
        state.current.extentChanged = false;
        state.current.styledata = true;
      }
    });

    mapbox.on("idle", () => {
      const today = new Date();
      if (state.current.styledata) {
        state.current.isIdle = true;
      }
      dispatch(
        setMapIdleTime(
          today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
        )
      );
    });
    mapbox.on("move", (data) => {
      const center = data.target.getCenter();
      const zoom = data.target.getZoom();
      const newMapState = {
        lng: center.lng,
        lat: center.lat,
        zoom: zoom
      };
      try {
        dispatch(setMapState(newMapState));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });

    // Catch Errors
    mapbox.on("error", (e) => {
      //eslint-disable-next-line
      console.error("map error", e);
    });
    return () => {
      mapDispatch({
        payload: {
          isMapInitialized: false
        }
      });
      mapbox.off("zoomstart");
      mapbox.off("style.load", onStyleLoaded);
      mapboxRef.current = undefined;
      // cleanupFn && cleanupFn();
      mapbox.remove();
    };
  }, [isDefaultMapSettingsLoaded]);

  useEffect(() => {
    const mapbox = mapboxRef.current;
    if (!mapbox) {
      return;
    }

    mapbox.on("mousemove", onMouseMove);
    mapbox.on("mouseleave", [WELL_LAYER, WELL_LAYER_POINT], onMouseLeave);

    const onClick = (e) => {
      const mapbox = mapboxRef.current;

      if (!mapbox || !mapbox.getLayer(WELL_LAYER)) {
        return;
      }
      const buffer = 2;
      const bbox = [
        [e.point.x - buffer, e.point.y - buffer],
        [e.point.x + buffer, e.point.y + buffer]
      ] as [PointLike, PointLike];
      const features = mapbox.queryRenderedFeatures(bbox, {
        layers: [WELL_LAYER, WELL_LAYER_POINT]
      });
      if (features.length === 0 || !features[0]) {
        return;
      }
      const selectedWells = {};
      const feat = features[0];
      if (!feat) return;
      selectedWells[feat.properties.Uwi] = feat.properties;
      const uwis = Object.keys(selectedWells);
      const labelFeatures = mapbox.querySourceFeatures(WELL_LABEL_LAYER, {
        sourceLayer: "eva-wells-label",
        filter: ["in", ["get", "Uwi"], ["literal", uwis]]
      });
      const labelDict = {};
      for (const label of labelFeatures) {
        if (!labelDict[label.properties.Uwi]) {
          labelDict[label.properties.Uwi] = label.properties.value;
        }
      }
      for (const uwi of Object.keys(selectedWells)) {
        if (labelDict[uwi]) {
          selectedWells[uwi]["value"] = labelDict[uwi];
        }
      }
      dispatch(setSelectedWells(selectedWells));
    };

    mapbox.on("click", onClick);

    return () => {
      mapbox.off("mousemove", onMouseMove);
      mapbox.off("mouseleave", onMouseLeave);
      mapbox.off("click", onClick);
    };
  }, [dispatch, mapboxRef?.current, onMouseLeave, onMouseMove]);

  useEffect(() => {
    // Highlighting from chart (attentionWells)
    // Removed useEffect with dependency on attentionWells. Highlighting was not working sporadically,
    // or after switching projects.
    if (!mapboxRef?.current || !attentionWells) return;

    const mapbox = mapboxRef.current;

    const attentionSource: mapboxgl.GeoJSONSource =
      mapbox.getSource(ATTENTION_WELL_LAYER);

    if (attentionSource?.setData) {
      if (attentionWells.length) {
        const wells = mapbox.querySourceFeatures(WELL_LAYER, {
          sourceLayer: "eva-wells",
          filter: ["in", ["get", "Uwi"], ["literal", attentionWells]]
        });

        const highlightedLineThickness = getHighlightedLineThickness();
        mapbox.setPaintProperty(
          ATTENTION_WELL_LAYER,
          "line-width",
          highlightedLineThickness
        );

        const features = wells.map((feat) => feature(feat.geometry));
        const collection = featureCollection(features);
        attentionSource.setData(collection);
      } else {
        attentionSource.setData(emptyFeatureCollection);
      }
    }
  }, [attentionWells, mapboxRef]);

  return (
    <div id={mapId.current} className="fill">
      <div
        id="map-screenshot-overlay"
        className="map-copy-overlay-container full-width full-height"></div>
    </div>
  );
}
