import { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";

import { ErrorOutline, ErrorOutlined } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import BlockIcon from "@material-ui/icons/Block";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import EmailIcon from "@material-ui/icons/Email";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { Button, Checkbox, Modal, Table } from "antd";
import axios from "axios";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { deleteUsers, updateUsersMfa, updateUsersRole } from "api/admin";
import { getUsersInOrg, postMemberInvite, postMemberResendInvite } from "api/users";

import {
  Avatar,
  BaseButton,
  BaseCheckboxInput,
  BaseDropdown,
  BaseIconToggle,
  BaseMenu,
  BaseTooltip,
  Heading,
  Tooltip
} from "components/base";
import BaseModal from "components/base/BaseModal";
import TextArea from "components/base/TextArea";
import ChangeUserType from "components/icons/ChangeUserType";
import TwoFactorActive from "components/icons/TwoFactorActive";
import TwoFactorAuth from "components/icons/TwoFactorAuth";
import TwoFactorInactive from "components/icons/TwoFactorInactive";

import "./OrganizationMembers.scss";

const userServiceRoot = process.env.REACT_APP_USER_SERVICE;

const UNLIMITED_USERS_CONSTANT = 999;
interface ResetPasswordResponse {
  password: string;
}
function getDisplayName(user) {
  if (user.firstName && user.lastName) {
    return user.firstName + " " + user.lastName;
  }
  return user.email;
}
function toRoleString(role) {
  if (role === 0) {
    return "Read Only";
  }
  if (role === 1) {
    return "Standard";
  }
  if (role === 2) {
    return "Admin";
  }
  if (role === 3) {
    return "Super User";
  }
  if (role === 4) {
    return "Power User";
  }
}

function getUsersToDeleteName(users) {
  const names = users.map((user) => getDisplayName(user));
  if (names.length === 1) return names[0];
  const firsts = names.slice(0, names.length - 1);
  const last = names[names.length - 1];
  return firsts.join(", ") + " and " + last;
}

const user_type_options = [
  { label: "Admin", value: "Admin" },
  { label: "Power User", value: "PowerUser" },
  { label: "Standard", value: "User" },
  { label: "Read Only", value: "Readonly" }
];
const user_2fa_options = [
  { label: "2FA On", value: true },
  { label: "2FA Off", value: false }
];

function UsersTable({
  users,
  handleUpdateRole,
  handleUpdateMfa,
  handleSetUpDelete,
  handlePasswordReset
}) {
  const [isChangeUserTypeToggled, setIsChangeUserTypeToggled] = useState(false);
  const [isChangeTwoFactorToggled, setIsChangeTwoFactorToggled] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const haveCheckedItems = selectedRows.length;
  const organization = useSelector((state: RootState) => state.auth.user.organization);

  function batchUpdateRole(value, ids = selectedRows) {
    handleUpdateRole(ids, value);
  }
  function batchUpdateMfa(value, ids = selectedRows) {
    handleUpdateMfa(ids, value);
  }
  function batchDelete(ids = selectedRows) {
    handleSetUpDelete(ids);
  }
  function batchResendInvite(emails) {
    postMemberResendInvite(
      {
        memberEmails: emails
      },
      () => {
        toast.success("Invite resent");
      },
      () => {
        toast.error("Failed to resend invite, please try again later.");
      }
    );
  }

  const columns = [
    {
      title: "User",
      dataIndex: "displayName",
      width: "100px",
      render: (text, record) => (
        <div className="flex-row">
          <Avatar initials={true} appearance="circle" user={record} />
          <div className="flex-column">
            <span className="user-name">{getDisplayName(record)}</span>
            <span className="user-email">{record.email}</span>
          </div>
        </div>
      ),
      sorter: (a, b) => getDisplayName(a).localeCompare(getDisplayName(b)),
      defaultSortOrder: "ascend" as const
    },
    {
      title: "User Type",
      dataIndex: "role",
      width: "20%",
      render: (text, record) => (
        <BaseDropdown
          value={toRoleString(text)}
          onChange={(opt) => batchUpdateRole(opt.value, [record.id])}
          options={user_type_options}
        />
      ),
      sorter: (a, b) => a.role - b.role
    },
    {
      title: "2FA",
      dataIndex: "requiresMfa",
      width: "15%",
      render: (text) =>
        organization.requireMfaForOrganization || text ? (
          <TwoFactorActive data-testid="user-mfa-active" />
        ) : (
          <TwoFactorInactive data-testid="user-mfa-inactive" />
        ),
      sorter: (a, b) => a.requiresMfa - b.requiresMfa
    },

    {
      title: "",
      width: "5%",
      dataIndex: "reinvite",
      render: (text, record) =>
        !record.emailVerified ? (
          <Tooltip className="hover-reinvite-icon" title="Resend Invite">
            <EmailIcon
              style={{ fontSize: "24px" }}
              onClick={() => batchResendInvite([record.email])}
            />
          </Tooltip>
        ) : (
          <ResetPasswordButton>
            <Tooltip
              className="hover-password-icon"
              title="Reset Password"
              mouseLeaveDelay={0}>
              <VpnKeyIcon
                style={{ fontSize: "24px", alignSelf: "center" }}
                onClick={() => handlePasswordReset(record.email)}
              />
            </Tooltip>
          </ResetPasswordButton>
        )
    },
    {
      title: "",
      width: "6%",
      dataIndex: "delete",
      render: (text, record) => (
        <DeleteUserButton>
          <Tooltip className="hover-delete-icon" title="Delete User">
            <DeleteIcon
              style={{ fontSize: "24px" }}
              onClick={() => batchDelete([record.id])}
            />
          </Tooltip>
        </DeleteUserButton>
      )
    }
  ];

  return (
    <div className="table-container">
      <div className="header-actions">
        <div className="flex-row justify-between items-center">
          <div
            className={`batch-actions flex-row ${
              !haveCheckedItems ? "actions-disabled" : ""
            }`}>
            <BaseTooltip text="Change User Type" position="top">
              <BaseMenu
                onOpen={() => setIsChangeUserTypeToggled(true)}
                onClose={() => setIsChangeUserTypeToggled(false)}
                trigger={
                  <BaseIconToggle value={isChangeUserTypeToggled} size={36}>
                    <ChangeUserType />
                  </BaseIconToggle>
                }>
                {({ closeMenu }) => {
                  return (
                    <div className="batch-user-types">
                      {user_type_options.map((type) => (
                        <div
                          className="user-types-item"
                          onClick={() => {
                            batchUpdateRole(type.value);
                            closeMenu();
                          }}
                          key={type.label}>
                          <div className="user-types-item-label">{type.label}</div>
                        </div>
                      ))}
                    </div>
                  );
                }}
              </BaseMenu>
            </BaseTooltip>
            {organization.maximumUsers >= UNLIMITED_USERS_CONSTANT &&
              !organization.requireMfaForOrganization && (
                <BaseTooltip text="Multi Factor Authentication" position="top">
                  <BaseMenu
                    onOpen={() => setIsChangeTwoFactorToggled(true)}
                    onClose={() => setIsChangeTwoFactorToggled(false)}
                    trigger={
                      <BaseIconToggle
                        value={isChangeTwoFactorToggled}
                        size={36}
                        data-testid="batch-mfa-toggle">
                        <TwoFactorAuth />
                      </BaseIconToggle>
                    }>
                    {({ closeMenu }) => {
                      return (
                        <div className="batch-user-2fa">
                          {user_2fa_options.map((opt) => (
                            <div
                              className="user-2fa-item"
                              onClick={() => {
                                batchUpdateMfa(opt.value);
                                closeMenu();
                              }}
                              key={opt.label}>
                              <div className="user-2fa-item-icon">
                                {opt.label === "2FA On" && (
                                  <CheckIcon style={{ fontSize: "20px" }} />
                                )}
                                {opt.label === "2FA Off" && (
                                  <BlockIcon style={{ fontSize: "20px" }} />
                                )}
                              </div>
                              <div className="user-2fa-item-label">{opt.label}</div>
                            </div>
                          ))}
                        </div>
                      );
                    }}
                  </BaseMenu>
                </BaseTooltip>
              )}
            <BaseIconToggle
              size={36}
              className="delete-users"
              toggle={() => batchDelete()}>
              <DeleteIcon style={{ fontSize: "24px" }} />
            </BaseIconToggle>
          </div>

          <div>
            <span className="user-count">
              {users.length}/{organization.maximumUsers} users added
            </span>
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        sortDirections={["ascend", "descend", "ascend"]}
        dataSource={users}
        pagination={{
          pageSize: 15,
          showSizeChanger: false,
          hideOnSinglePage: true
        }}
        rowSelection={{
          type: "checkbox",
          selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
          selectedRowKeys: selectedRows,
          onChange: (selectedRowKeys) => {
            setSelectedRows([...selectedRowKeys]);
          }
        }}
      />
    </div>
  );
}

export default function OrganizationMembers() {
  const organization = useSelector((state: RootState) => state.auth.user.organization);

  const [users, setUsers] = useState([]);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showPasswordResetDialog, setShowPasswordResetDialog] = useState(false);
  const [deleteCheckbox, setDeleteCheckbox] = useState(false);
  const [usersToDelete, setUsersToDelete] = useState([]);
  const [userToResetPassword, setUserToResetPassword] = useState({
    firstName: "",
    lastName: "",
    email: "",
    id: ""
  });
  const [resetPasswordCheckbox, setResetPasswordCheckbox] = useState(false);
  const [inviteEmails, setInviteEmails] = useState("");
  const [copyTooltipText, setCopyTooltipText] = useState("Copy to clipboard");

  const [error, setError] = useState(undefined);
  const updateUsers = useCallback(async () => {
    try {
      setError(undefined);
      const users = await getUsersInOrg(organization.id);
      setUsers(users.map((user) => ({ ...user, key: user.id })));
    } catch (err) {
      if (err.response?.data) {
        setError(`${err?.response.data}`);
      } else {
        setError("We're sorry, user list could not be retrieved to network error. ");
      }
    }
  }, [organization.id]);

  useEffect(() => {
    updateUsers();
  }, [organization.id, updateUsers]);

  const sendEmailInvite = useCallback(() => {
    setError("");
    const emails = inviteEmails.split(",").map((item) => item.trim());
    postMemberInvite(
      {
        memberEmails: emails
      },
      () => {
        updateUsers();
        setShowInviteDialog(false);
      },
      (err) => {
        if (err.response?.data) {
          setError(`${err?.response.data}`);
        } else {
          setError("We're sorry, invite emails could not be sent due to network error.");
        }
      }
    );
  }, [inviteEmails, updateUsers]);

  async function handleUpdateRole(userIds, value) {
    try {
      const updatedUsers = await updateUsersRole({
        organizationId: organization.id,
        data: {
          userIds,
          role: value
        }
      });
      setUsers(updatedUsers.map((user) => ({ ...user, key: user.id })));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  async function handleUpdateMfa(userIds, value) {
    try {
      const updatedUsers = await updateUsersMfa({
        organizationId: organization.id,
        data: {
          userIds,
          mfaOn: value
        }
      });
      setUsers(updatedUsers.map((user) => ({ ...user, key: user.id })));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  async function handleSetUpDelete(ids) {
    const toDelete = ids.map((id) => users.find((u) => u.id === id));
    setShowDeleteDialog(true);
    setUsersToDelete(toDelete);
  }

  async function handleConfirmDelete() {
    try {
      const ids = usersToDelete.map((u) => u.id);
      const updatedUsers = await deleteUsers({
        organizationId: organization.id,
        data: {
          userIds: ids
        }
      });
      setUsers(updatedUsers.map((user) => ({ ...user, key: user.id })));
      setDeleteCheckbox(false);
      setShowDeleteDialog(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  async function handleResetPassword(email) {
    const toReset = users.find((u) => u.email === email);
    setUserToResetPassword(toReset);
    setShowPasswordResetDialog(true);
  }

  const resetPasswordMutation = useMutation(async (userId: string) => {
    return await axios.post<ResetPasswordResponse>(
      `${userServiceRoot}/admin/user/${userId}/generate-password`
    );
  });
  const tableData = [...users];

  function onCancel() {
    setShowPasswordResetDialog(false);
  }

  useEffect(() => {
    if (copyTooltipText == "Copied!") {
      setTimeout(() => {
        setCopyTooltipText("Copy to clipboard");
      }, 2000);
    }
  }, [copyTooltipText]);

  return (
    <div className="OrganizationMembers">
      <div className="organizationAccountHeader flex-row justify-between">
        <Heading element="h4">Users</Heading>
        <BaseButton
          className="invite-user-btn"
          appearance="subtle"
          onClick={() => setShowInviteDialog(true)}
          isDisabled={users.length < organization.maximumUsers ? false : true}>
          <AddIcon style={{ top: "2px", right: "3px" }}></AddIcon>Add Users
        </BaseButton>
      </div>

      <UsersTable
        users={tableData}
        handleUpdateMfa={handleUpdateMfa}
        handleUpdateRole={handleUpdateRole}
        handleSetUpDelete={handleSetUpDelete}
        handlePasswordReset={handleResetPassword}
      />

      <BaseModal
        open={showInviteDialog}
        onClose={() => setShowInviteDialog(false)}
        size="sm"
        className="invite-users-email-dialog"
        title="Add Users by Email"
        subtitle="Users will receive an email invitation to join.">
        <div>
          <p
            className="align-center"
            style={{ fontSize: "16px", paddingBottom: "5px", color: "#1E2029" }}>
            Add multiple emails, separated by commas
          </p>
          <TextArea
            value={inviteEmails}
            onChange={(x) => {
              setInviteEmails(x);
            }}
            placeholder="email1@domain.com,email2@domain.com"
          />
        </div>
        {error && (
          <ErrorContainer>
            <ErrorOutlined /> <ErrorMessage error={error} />
          </ErrorContainer>
        )}
        <div className="flex-row justify-center" style={{ marginTop: "37px" }}>
          <BaseButton autoFocus onClick={sendEmailInvite} appearance="primary">
            Send Invite
          </BaseButton>
        </div>
      </BaseModal>
      <Modal
        open={showPasswordResetDialog}
        onCancel={onCancel}
        onOk={() => resetPasswordMutation.mutateAsync(userToResetPassword.id)}
        okButtonProps={{
          disabled: !resetPasswordCheckbox,
          loading: resetPasswordMutation.isLoading
        }}
        footer={
          resetPasswordMutation.data && (
            <Button type="primary" onClick={onCancel}>
              OK
            </Button>
          )
        }
        afterClose={() => {
          setResetPasswordCheckbox(false);
          resetPasswordMutation.reset();
        }}>
        {!resetPasswordMutation.data && (
          <div>
            <h4>Reset Password</h4>
            <p>
              Are you sure you want to reset {getUsersToDeleteName([userToResetPassword])}
              &apos;s password?
            </p>
            <PasswordResetConfirmation>
              <Checkbox
                checked={resetPasswordCheckbox}
                onChange={(e) => setResetPasswordCheckbox(e.target.checked)}></Checkbox>
              &nbsp;I understand this action cannot be undone
            </PasswordResetConfirmation>
          </div>
        )}
        {resetPasswordMutation.error && (
          <ErrorContainer>
            An error occurred:{" "}
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (resetPasswordMutation.error as any).response?.data.title ??
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (resetPasswordMutation.error as any).message
            }
          </ErrorContainer>
        )}
        {resetPasswordMutation.data && (
          <div>
            <h4>Password successfully reset</h4>
            <p>{getUsersToDeleteName([userToResetPassword])}&apos;s new password is:</p>
            <NewPasswordRow>
              <NewPasswordDisplay>
                {resetPasswordMutation.data.data.password}&nbsp;
              </NewPasswordDisplay>
              <Tooltip title={copyTooltipText}>
                <CopyToClipboardButton
                  onClick={() => {
                    navigator.clipboard.writeText(
                      resetPasswordMutation.data.data.password
                    );
                    setCopyTooltipText("Copied!");
                  }}></CopyToClipboardButton>
              </Tooltip>
            </NewPasswordRow>
          </div>
        )}
      </Modal>
      <BaseModal
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        size="xs"
        className="delete-users-email-dialog"
        title={
          <div className="flex-row items-center">
            <ErrorOutline style={{ fontSize: "40px" }} />
            {`Delete User${usersToDelete.length > 1 ? "s" : ""}`}
          </div>
        }>
        <div className="flex-column">
          <p>Are you sure you want to delete {getUsersToDeleteName(usersToDelete)}?</p>
          <div className="flex-row">
            <BaseCheckboxInput value={deleteCheckbox} onChange={setDeleteCheckbox} />
            <span>I understand this action cannot be undone.</span>
          </div>
        </div>
        <div className="flex-row" style={{ marginTop: "37px", gap: "24px" }}>
          <BaseButton
            autoFocus
            onClick={() => setShowDeleteDialog(false)}
            appearance="stroked">
            Cancel
          </BaseButton>
          <BaseButton
            autoFocus
            onClick={handleConfirmDelete}
            appearance="danger"
            isDisabled={!deleteCheckbox}>
            Delete
          </BaseButton>
        </div>
      </BaseModal>
      <ReactTooltip />
    </div>
  );
}

const ErrorMessage = ({ error }) => {
  const formattedError = error.replace(/\n/g, "<br/>");
  return <div dangerouslySetInnerHTML={{ __html: formattedError }} />;
};

const ErrorContainer = styled.div`
  color: red;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const ResetPasswordButton = styled.div`
  text-align: center;
`;

const DeleteUserButton = styled.div`
  text-align: center;
`;

const PasswordResetConfirmation = styled.div`
  display: flex;
  align-items: center;
  padding-top: 15px;
`;
const CopyToClipboardButton = styled(FileCopyIcon)`
  color: var(--color-action-button);
  &:hover {
    color: var(--color-hover);
  }
`;

const NewPasswordDisplay = styled.div`
  display: inline-block;
  padding: 10px;
  margin: 5px;
  text-align: center;
  background-color: rgba(var(--color-text-rgb), 0.05);
`;

const NewPasswordRow = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
