// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";

import { mdiDotsVertical } from "@mdi/js";
import { Button, Popover, Tooltip } from "antd";

import { ActionListWrapper } from "..";
import {
  ShapefileExportAction,
  ShapefileRemoveAction,
  ShapefileRenameAction
} from "./actions";
import { ShapefileDeleteAction } from "./actions/ShapefileDeleteAction";
import { ShapefileViewDetailsAction } from "./actions/ShapefileViewDetailsAction";

interface IShapefileActionsProps {
  deleteShapefile?: () => void;
  isDisabled?: boolean;
  removeShapefile?: () => void;
  setIsRenaming?: () => void;
  viewShapefileDetails?: () => void;
  exportShapefile?: () => string;
}

/**
 * Essentially a wrapper for the shapefile actions, so that the actions can be used in different contexts.
 * @param params
 * @returns
 */
export function ShapefileActions(params: IShapefileActionsProps): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const actionList = (
    <ActionListWrapper>
      {params.viewShapefileDetails && (
        <ShapefileViewDetailsAction
          closePopover={() => setIsPopoverOpen(false)}
          viewShapefileDetails={params.viewShapefileDetails}
        />
      )}

      {params.setIsRenaming && (
        <ShapefileRenameAction
          isDisabled={params.isDisabled}
          setIsRenaming={params.setIsRenaming}
        />
      )}

      {params.exportShapefile && (
        <ShapefileExportAction
          closePopover={() => setIsPopoverOpen(false)}
          shapefileId={params.exportShapefile()}
        />
      )}

      {params.deleteShapefile && (
        <ShapefileDeleteAction
          deleteShapefile={() => params.deleteShapefile()}
          isDisabled={params.isDisabled}
        />
      )}

      {params.removeShapefile && (
        <ShapefileRemoveAction
          isDisabled={params.isDisabled}
          removeShapefile={() => params.removeShapefile()}
        />
      )}
    </ActionListWrapper>
  );

  return (
    <Tooltip title="Actions">
      <Popover
        arrowPointAtCenter={true}
        content={actionList}
        onOpenChange={(isOpen) => {
          setIsPopoverOpen(isOpen);
        }}
        open={isPopoverOpen}
        overlayClassName="popover-no-padding"
        placement="bottomLeft"
        trigger="click">
        <Button type="text" className="shapefile-actions" aria-label="Edit">
          <Icon path={mdiDotsVertical} size={1} />
        </Button>
      </Popover>
    </Tooltip>
  );
}
