// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";

import { mdiDotsVertical } from "@mdi/js";
import { Button, Popover, Tooltip } from "antd";

import { ActionListWrapper } from "..";
import { ProjectShapefileFolderColorPicker } from "../shapefile-color-picker/ProjectShapefileFolderColorPicker";
import {
  ShapefileFolderDeleteAction,
  ShapefileFolderRemoveAction,
  ShapefileFolderRenameAction,
  ShapefileFolderSortAction
} from "./actions";
import { ShapefileFolderAddAction } from "./actions/ShapefileFolderAddAction";
import { ShapefileFolderExportAction } from "./actions/ShapefileFolderExportAction";

interface IShapefileActionsProps {
  addFolder?: () => void;
  deleteFolder?: () => void;
  hasColorPicker?: boolean;
  isDisabled?: boolean;
  removeFolder?: () => void;
  setIsRenaming?: () => void;
  shapefileNodeId?: string;
  sortShapefilesAscend?: () => void;
  sortShapefilesDescend?: () => void;
}

/**
 * Essentially a wrapper for the shapefile actions, so that the actions can be used in different contexts.
 * @param params
 * @returns
 */
export function ShapefileFolderActions(params: IShapefileActionsProps): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const actionList = (
    <ActionListWrapper>
      {params.addFolder && <ShapefileFolderAddAction addFolder={params.addFolder} />}

      {params.hasColorPicker && (
        <ProjectShapefileFolderColorPicker shapefileNodeId={params.shapefileNodeId} />
      )}

      {params.sortShapefilesAscend && (
        <ShapefileFolderSortAction
          setPopoverOpen={(isOpen) => setIsPopoverOpen(isOpen)}
          sortType="ascend"
          sortShapefiles={() => params.sortShapefilesAscend()}
        />
      )}

      {params.sortShapefilesDescend && (
        <ShapefileFolderSortAction
          setPopoverOpen={(isOpen) => setIsPopoverOpen(isOpen)}
          sortType="descend"
          sortShapefiles={() => params.sortShapefilesDescend()}
        />
      )}
      {params.setIsRenaming && (
        <ShapefileFolderRenameAction setIsRenaming={() => params.setIsRenaming()} />
      )}

      {params.shapefileNodeId && (
        <ShapefileFolderExportAction
          shapefileNodeId={params.shapefileNodeId}
          closePopover={() => setIsPopoverOpen(false)}
        />
      )}

      {params.deleteFolder && (
        <ShapefileFolderDeleteAction deleteShapefile={() => params.deleteFolder()} />
      )}

      {params.removeFolder && (
        <ShapefileFolderRemoveAction removeShapefile={() => params.removeFolder()} />
      )}
    </ActionListWrapper>
  );

  return (
    <Tooltip title="Actions">
      <Popover
        arrowPointAtCenter={true}
        content={actionList}
        onOpenChange={(isOpen) => {
          if (!params.isDisabled) {
            setIsPopoverOpen(isOpen);
          }
        }}
        open={isPopoverOpen}
        overlayClassName="popover-no-padding"
        placement="bottomLeft"
        trigger="click">
        <Button disabled={params.isDisabled} type="text" aria-label="Edit">
          <Icon path={mdiDotsVertical} size={1} />
        </Button>
      </Popover>
    </Tooltip>
  );
}
