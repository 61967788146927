import { RateDto } from "arps_wasm";
import { ALL_PRODUCT_TYPES, ChartTypeLabels } from "constants/chart.constants";

import { ArpSegment } from "models/UserArpsModel";

import { getSpacingBetweenValues } from "./arpsUtils";
import { compareRateDate, getDurationInDays, getDurationInMonths } from "./dates";
import { scaleDataBasedOnYAxisUnits } from "./scaleDataBasedOnYAxisUnits";

const TYPE_WELL_MAX_NUMBER_OF_YEARS_CAP = 100;

export function getTypeWellSeries(
  chartType: ChartTypeLabels,
  product: string,
  arps: ArpSegment[],
  constants: { unit: string; product: string; uniqueId: string; value: number }[],
  rateCum: RateDto[],
  normalizeValue: number,
  isDaily: boolean,
  checkForRampUpTw: boolean,
  yAxisTitle: string
) {
  let rampUpRateCum = [];
  // TODO: move ramp up segment calculation to arps-wasm lib
  if (checkForRampUpTw) {
    const gasSegments = arps.filter(
      (a) =>
        a.product && a.product.toLowerCase() === ALL_PRODUCT_TYPES.Gas.key.toLowerCase()
    );

    const gasRateCum = gasSegments?.length
      ? getRampUpRateCum(gasSegments, normalizeValue, isDaily)
      : [];

    const oilSegments = arps.filter(
      (a) =>
        a.product && a.product.toLowerCase() === ALL_PRODUCT_TYPES.Oil.key.toLowerCase()
    );

    const oilRateCum = oilSegments?.length
      ? getRampUpRateCum(oilSegments, normalizeValue, isDaily)
      : [];

    const condSegments = arps.filter(
      (a) =>
        a.product &&
        ((a.product && a.product.toLowerCase() === "cond.") ||
          a.product.toLowerCase() === "cond" ||
          a.product.toLowerCase() === "condensate")
    );

    const condRateCum = condSegments?.length
      ? getRampUpRateCum(condSegments, normalizeValue, isDaily)
      : [];

    const waterSegments = arps.filter(
      (a) =>
        a.product && a.product.toLowerCase() === ALL_PRODUCT_TYPES.Water.key.toLowerCase()
    );

    const waterRateCum = waterSegments?.length
      ? getRampUpRateCum(waterSegments, normalizeValue, isDaily)
      : [];

    const cgrRateCum = [];
    const ogrRateCum = [];
    const wgrRateCum = [];
    const gorRateCum = [];
    const worRateCum = [];

    if (gasRateCum?.length) {
      const ogrConstant = constants.find(
        (c) => c.product.toLowerCase() === "ogr" && c.value > 0
      );
      if (ogrConstant) {
        ogrRateCum.push(...getRampUpRatioRateCumFromProduct(gasRateCum, ogrConstant));

        if (!oilRateCum?.length) {
          // Oil from gas and ratio
          oilRateCum.push(
            ...getRampUpProductRateCumFromRatio(gasRateCum, ogrConstant, 0.001)
          );
        }
      }

      const cgrConstant = constants.find(
        (c) =>
          c.product.toLowerCase() === ALL_PRODUCT_TYPES.CGR.key.toLowerCase() &&
          c.value > 0
      );
      if (cgrConstant) {
        cgrRateCum.push(...getRampUpRatioRateCumFromProduct(gasRateCum, cgrConstant));
        if (!condRateCum?.length) {
          // Cond from gas and ratio
          condRateCum.push(
            ...getRampUpProductRateCumFromRatio(gasRateCum, cgrConstant, 0.001)
          );
        }
      }

      const wgrConstant = constants.find(
        (c) =>
          c.product.toLowerCase() === ALL_PRODUCT_TYPES.WGR.key.toLowerCase() &&
          c.value > 0
      );
      if (wgrConstant) {
        if (!waterRateCum?.length) {
          wgrRateCum.push(...getRampUpRatioRateCumFromProduct(gasRateCum, wgrConstant));
          // Water from gas and ratio
          waterRateCum.push(
            ...getRampUpProductRateCumFromRatio(gasRateCum, wgrConstant, 0.001)
          );
        }
      }
    }

    if (oilRateCum?.length) {
      const gorConstant = constants.find(
        (c) =>
          c.product.toLowerCase() === ALL_PRODUCT_TYPES.GOR.key.toLowerCase() &&
          c.value > 0
      );
      if (gorConstant) {
        gorRateCum.push(...getRampUpRatioRateCumFromProduct(oilRateCum, gorConstant));
        if (!gasRateCum?.length) {
          // Gas from oil and ratio
          gasRateCum.push(
            ...getRampUpProductRateCumFromRatio(oilRateCum, gorConstant, 0.001)
          );
        }
      }

      const worConstant = constants.find(
        (c) =>
          c.product.toLowerCase() === ALL_PRODUCT_TYPES.WOR.key.toLowerCase() &&
          c.value > 0
      );
      if (worConstant) {
        worRateCum.push(...getRampUpRatioRateCumFromProduct(oilRateCum, worConstant));
        if (!waterRateCum?.length) {
          // Water from oil and ratio
          waterRateCum.push(
            ...getRampUpProductRateCumFromRatio(oilRateCum, worConstant, 1.0)
          );
        }
      }
    }

    if (condRateCum?.length) {
      const gorConstant = constants.find(
        (c) =>
          c.product.toLowerCase() === ALL_PRODUCT_TYPES.GOR.key.toLowerCase() &&
          c.value > 0
      );
      if (gorConstant) {
        gorRateCum.push(...getRampUpRatioRateCumFromProduct(condRateCum, gorConstant));
        if (!gasRateCum?.length) {
          // Gas from cond and ratio
          gasRateCum.push(
            ...getRampUpProductRateCumFromRatio(condRateCum, gorConstant, 0.001)
          );
        }
      }

      const worConstant = constants.find(
        (c) =>
          c.product.toLowerCase() === ALL_PRODUCT_TYPES.WOR.key.toLowerCase() &&
          c.value > 0
      );
      if (worConstant) {
        worRateCum.push(...getRampUpRatioRateCumFromProduct(condRateCum, worConstant));
        if (!waterRateCum?.length) {
          // Water from cond and ratio
          waterRateCum.push(
            ...getRampUpProductRateCumFromRatio(condRateCum, worConstant, 1.0)
          );
        }
      }
    }

    if (product == ALL_PRODUCT_TYPES.Gas.key) {
      if (gasRateCum?.length) {
        rampUpRateCum = gasRateCum;
      }
    }

    if (product == ALL_PRODUCT_TYPES.Water.key) {
      if (waterRateCum?.length) {
        rampUpRateCum = waterRateCum;
      }
    }

    if (product == ALL_PRODUCT_TYPES.Oil.key) {
      if (oilRateCum?.length && !condRateCum?.length) {
        rampUpRateCum = rampUpRateCum.concat(oilRateCum);
      } else if (!oilRateCum?.length && condRateCum?.length) {
        rampUpRateCum = rampUpRateCum.concat(condRateCum);
      } else {
        rampUpRateCum = rampUpRateCum.concat(oilRateCum);

        let idx = 0;
        condRateCum.forEach((c) => {
          const matchingOil = rampUpRateCum.find(
            (o) => c.year == o.year && c.month == o.month && c.day == o.day
          );

          if (matchingOil) {
            matchingOil.rate += c.rate;
            matchingOil.cum += c.cum;
          } else {
            rampUpRateCum.splice(idx, 0, c);
            idx++;
          }

          idx++;
        });
      }
    }

    if (product.toLowerCase() == "total") {
      if (oilRateCum?.length) {
        rampUpRateCum = rampUpRateCum.concat(oilRateCum);

        oilRateCum.forEach((o, idx) => {
          const matchingWater = waterRateCum[idx];

          if (matchingWater) {
            rampUpRateCum[idx].rate += matchingWater.rate;
            rampUpRateCum[idx].cum += matchingWater.cum;
          }
        });
      } else {
        rampUpRateCum = rampUpRateCum.concat(waterRateCum);

        waterRateCum.forEach((g, idx) => {
          const matchingOil = oilRateCum[idx];

          if (matchingOil) {
            rampUpRateCum[idx].rate += matchingOil.rate;
            rampUpRateCum[idx].cum += matchingOil.cum;
          }
        });
      }
    }

    if (product == ALL_PRODUCT_TYPES.BOE.key) {
      if (oilRateCum?.length) {
        rampUpRateCum = rampUpRateCum.concat(oilRateCum);

        oilRateCum.forEach((o, idx) => {
          const matchingGas = gasRateCum[idx];

          if (matchingGas) {
            rampUpRateCum[idx].rate += matchingGas.rate;
            rampUpRateCum[idx].cum += matchingGas.cum;
          }
        });
      } else {
        rampUpRateCum = rampUpRateCum.concat(gasRateCum);

        gasRateCum.forEach((g, idx) => {
          const matchingOil = oilRateCum[idx];

          if (matchingOil) {
            rampUpRateCum[idx].rate += matchingOil.rate;
            rampUpRateCum[idx].cum += matchingOil.cum;
          }
        });
      }
    }

    if (product == ALL_PRODUCT_TYPES.CGR.key) {
      rampUpRateCum = rampUpRateCum.concat(cgrRateCum);
    }

    if (product == ALL_PRODUCT_TYPES.WGR.key) {
      rampUpRateCum = rampUpRateCum.concat(wgrRateCum);
    }

    if (product == ALL_PRODUCT_TYPES.GOR.key) {
      rampUpRateCum = rampUpRateCum.concat(gorRateCum);
    }

    if (product == ALL_PRODUCT_TYPES.WOR.key) {
      rampUpRateCum = rampUpRateCum.concat(worRateCum);
    }

    rampUpRateCum.sort(compareRateDate);
  }

  if (chartType == "Rate Cum") {
    if (rampUpRateCum.length) {
      const series = [];

      let rampUpCum = 0;
      rampUpRateCum.forEach((rc) => {
        rampUpCum = rc.cum * 0.001;
        series.push([rampUpCum, rc.rate, undefined]);
      });

      rateCum.forEach((rc, idx) => {
        if (idx > 0) {
          series.push([rc.cum * 0.001 + rampUpCum, rc.rate, undefined]);
        }
      });

      return series;
    }

    return rateCum.map((rc) => {
      return [rc.cum * 0.001, rc.rate, undefined];
    });
  }
  if (chartType == "Rate Time") {
    const series = [];
    let idx = 1;

    rampUpRateCum.forEach((rc) => {
      series.push([idx, rc.rate, undefined]);
      idx++;
    });

    rateCum.forEach((rc, i) => {
      if (!rampUpRateCum.length || i > 0) {
        series.push([idx, rc.rate, undefined]);
        idx++;
      }
    });

    return series;
  }
  if (chartType == "Cum Time") {
    const scale = scaleDataBasedOnYAxisUnits(yAxisTitle);
    if (rampUpRateCum.length) {
      const series = [];
      let idx = 1;

      let rampUpCum = 0;
      rampUpRateCum.forEach((rc) => {
        rampUpCum = rc.cum;
        series.push([idx, rc.cum * scale, undefined]);
        idx++;
      });

      rateCum.forEach((rc, i) => {
        if (i > 0) {
          series.push([idx, (rc.cum + rampUpCum) * scale, undefined]);
          idx++;
        }
      });

      return series;
    } else {
      return rateCum.map((rc, i) => {
        return [i + 1, rc.cum * scale, undefined];
      });
    }
  }
  if (chartType == "Rate Date") {
    const scale = scaleDataBasedOnYAxisUnits(yAxisTitle);
    const series = [];

    rampUpRateCum.forEach((rc) => {
      series.push([
        rc.year +
          "-" +
          rc.month.toString().padStart(2, "0") +
          "-" +
          rc.day.toString().padStart(2, "0") +
          "T00:00:00",
        rc.rate * scale,
        undefined
      ]);
    });

    const date = new Date();
    const maxYear = date.getFullYear() + TYPE_WELL_MAX_NUMBER_OF_YEARS_CAP;

    rateCum
      .filter((rc, idx) => {
        return (
          idx > 0 &&
          (rc.year < maxYear || (rc.year === maxYear && rc.month === 1 && rc.day === 1))
        );
      })
      .forEach((rc) => {
        series.push([
          rc.year +
            "-" +
            rc.month.toString().padStart(2, "0") +
            "-" +
            rc.day.toString().padStart(2, "0") +
            "T00:00:00",
          rc.rate * scale,
          undefined
        ]);
      });

    return series;
  }
}

export function getRampUpProductRateCumFromRatio(
  rateCum: RateDto[],
  constant: { unit: string; product: string; uniqueId: string; value: number },
  ratio: number
) {
  return rateCum.map((rc) => {
    return {
      ...rc,
      rate: rc.rate * constant.value * ratio,
      cum: rc.cum * constant.value * ratio
    };
  });
}

export function getRampUpRatioRateCumFromProduct(
  rateCum: RateDto[],
  constant: { unit: string; product: string; uniqueId: string; value: number }
) {
  return rateCum.map((rc) => {
    return {
      ...rc,
      rate: constant.value,
      cum: rc.cum
    };
  });
}

export function getRampUpRateCum(
  arps: ArpSegment[],
  normalizeValue: number,
  isDaily: boolean
) {
  const rateCum = [];

  if (!arps?.length) {
    return rateCum;
  }

  const rampUpSegment = arps[0];

  if (rampUpSegment.qi > rampUpSegment.qf && rampUpSegment.di > 0) {
    // This is not a ramp-up segment
    return rateCum;
  }

  const difference = isDaily
    ? getDurationInDays(rampUpSegment.startDate, rampUpSegment.endDate)
    : getDurationInMonths(rampUpSegment.startDate, rampUpSegment.endDate);

  const dayDifference = getDurationInDays(rampUpSegment.startDate, rampUpSegment.endDate);

  const daySpacing = getSpacingBetweenValues(
    rampUpSegment.qi,
    rampUpSegment.qf,
    dayDifference
  );

  let start = new Date(rampUpSegment.startDate.split("Z")[0]);
  const endDate = new Date(rampUpSegment.endDate.split("Z")[0]);

  let idx = 0;
  let currentCum = 0;
  let currentRate = rampUpSegment.qi;
  const maxRate = rampUpSegment?.maxRate;
  while (idx <= difference) {
    const rc = {
      year: start.getFullYear(),
      month: start.getMonth() + 1,
      day: idx == 0 || isDaily ? start.getDate() : 1,
      // respect max rate unless default value of zero
      rate: Math.min(currentRate, maxRate || currentRate) * normalizeValue,
      cum: currentCum * normalizeValue
    };

    if (isDaily) {
      currentRate += daySpacing;
      currentCum += currentRate;

      start.setDate(start.getDate() + 1);
    } else if (start < endDate) {
      const nextDay = new Date(start.getFullYear(), start.getMonth() + 1, 0);
      // stop right before end date
      const days = nextDay < endDate ? nextDay.getDate() : endDate.getDate() - 1;

      for (let i = 1; i <= days; i++) {
        currentRate += daySpacing;
        currentCum += currentRate;
      }

      start.setMonth(start.getMonth() + 1);
      if (start > endDate) {
        start = endDate;
      }

      if (idx > 0) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (rc.rate = Math.min(currentRate, maxRate || currentRate) * normalizeValue),
          (rc.cum = currentCum * normalizeValue);
      }
    }

    rateCum.push(rc);

    idx++;
  }

  return rateCum;
}
