import { IEditableProjectLayer } from "components/project/layers/hooks";
import { Shapefile } from "components/project/shapefiles/queries";

import { PROPERTY_SHAPEFILE_TYPE } from "../constants";
import { addShapefilePropertyLayer } from "./addShapefilePropertyLayer";

interface IOnShapefilePropertyChanged {
  addLayer;
  currentShapefileFeatureProperty: string;
  currentShapefileIdForFeatureProperty: string;
  lastShapefileFeatureProperty: React.MutableRefObject<string>;
  lastShapefileIdForFeatureProperty: React.MutableRefObject<string>;
  layerList: IEditableProjectLayer[];
  mapbox: mapboxgl.Map;
  shapefileFeatures;
  shapefileListSorted: Shapefile[];
}

/**
 * Since the shapefile property isn't loading for every shapefile, it's easier to manage the layer on it's own depending on the 'current' selected shapefile/property
 * Otherwise, when managed in the onShapefilesChanged function, it would potentially not trigger the load because the source has already been loaded, but the propety hadn't been set yet
 * And for the shapefile property, the source doesn't need to be updated.
 * @param params
 * @returns
 */
export function onShapefilePropertyChanged(params: IOnShapefilePropertyChanged) {
  const {
    addLayer,
    currentShapefileFeatureProperty,
    layerList,
    mapbox,
    shapefileFeatures,
    shapefileListSorted,
    currentShapefileIdForFeatureProperty
  } = params;

  if (
    !shapefileListSorted?.length ||
    !currentShapefileFeatureProperty ||
    !Object.keys(shapefileFeatures)?.length ||
    !currentShapefileIdForFeatureProperty
  ) {
    return;
  }

  const hasShapefilePropertyChanged = !(
    currentShapefileFeatureProperty === params.lastShapefileFeatureProperty.current &&
    currentShapefileIdForFeatureProperty ===
      params.lastShapefileIdForFeatureProperty.current
  );

  const currentLayerName = `${currentShapefileIdForFeatureProperty}${PROPERTY_SHAPEFILE_TYPE}`;

  // The && !mapbox.getLayer(currentLayerName) check is to cover the case of the shapefile being unchecked,
  // this way it'll add the property layer back when it's checked again
  if (
    hasShapefilePropertyChanged ||
    (!hasShapefilePropertyChanged && !mapbox.getLayer(currentLayerName))
  ) {
    const lastLayerName = `${params.lastShapefileIdForFeatureProperty.current}${PROPERTY_SHAPEFILE_TYPE}`;
    if (mapbox.getLayer(lastLayerName)) mapbox.removeLayer(lastLayerName);

    const shapefile = shapefileListSorted.find(
      (shapefile) =>
        shapefile.projectShapefileId === currentShapefileIdForFeatureProperty ||
        shapefile.shapefileId === currentShapefileIdForFeatureProperty
    );

    if (shapefile && mapbox.getSource(currentShapefileIdForFeatureProperty)) {
      addShapefilePropertyLayer({
        addLayer,
        layerList,
        shapefile,
        shapefileFeatureProperty: currentShapefileFeatureProperty,
        shapefileFeatures,
        shapefileId: currentShapefileIdForFeatureProperty
      });

      params.lastShapefileFeatureProperty.current = currentShapefileFeatureProperty;
      params.lastShapefileIdForFeatureProperty.current =
        currentShapefileIdForFeatureProperty;
    }
  }
}
