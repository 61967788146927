import { Icon } from "@mdi/react";
import { Row, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { FileCopyOutlined } from "@material-ui/icons";
import { mdiCheck, mdiCog } from "@mdi/js";
import { Geometry } from "@turf/helpers";
import {
  Alert,
  Button,
  Card,
  InputNumber,
  Popover,
  Radio,
  Select,
  TreeSelect,
  Typography
} from "antd";
import axios from "axios";
import { setExploitableArea } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { GetShapefileBranch } from "api/project";

import { useSelectedProject } from "components/project/projects/hooks";

import useExploitableReport from "./hooks/useExploitableReport";
import { ExploitableReportT } from "./models/exploitableReport";

const { Text } = Typography;
const ButtonGroup = Button.Group;

type AreaUnit = "m2" | "ha" | "section";
const SquareSection = 2560000;
export default function ExploitableReport() {
  const tableRef = useRef<HTMLTableElement>(null);
  const [shapefiles, setShapefiles] = useState([]);
  const txnId = useSelector((state: RootState) => state.map.txnId);
  const shapefilesDictRef = useRef<{ [shapefile: string]: unknown }>({});
  const [selectedFolder, setSelectedFolder] = useState();
  const { selectedProject } = useSelectedProject();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [wellSpacing, setWellSpacing] = useState(400);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [areaUnit, setAreaUnit] = useState<AreaUnit>("ha");
  const [checkedRow, setCheckedRow] = useState<string>();
  const dispatch = useDispatch();
  // eslint-disable-next-line import/no-named-as-default-member
  const cancelTokenSourceRef = useRef(axios.CancelToken.source());
  const {
    data: report,
    isLoading,
    isFetching,
    isError,
    refetch
  } = useExploitableReport(
    selectedFolder,
    txnId.id,
    wellSpacing,
    cancelTokenSourceRef.current.token
  );
  const table = useReactTable({
    columns: toColumns(report),
    data: toTableDataSource(report),
    getCoreRowModel: getCoreRowModel()
  });

  useEffect(() => {
    if (!selectedProject) {
      return;
    }
    setSelectedFolder(undefined);
    GetShapefileBranch(
      selectedProject.projectId,
      (nodes) => {
        setErrorMessage("");
        const parseShapefileNodes = (node, shapefilesDict) => {
          return {
            value: node.shapefileId ? node.shapefileId : node.shapefileNodeId,
            title: node.title,
            selectable: node.type === "node",
            children: (
              node.children?.map((ch) => parseShapefileNodes(ch, shapefilesDict)) ?? []
            ).filter((item) => item.selectable)
          };
        };

        //clear out existing shapefiles
        shapefilesDictRef.current = {};

        const shapefileNodes = nodes.children
          .map((node) => parseShapefileNodes(node, shapefilesDictRef.current))
          //remove empty folders
          .filter((node) => node.selectable);
        if (shapefileNodes.length > 0) {
          setShapefiles(
            shapefileNodes[0].title === "Supply Stack"
              ? shapefileNodes[0].children
              : shapefileNodes
          );
        } else {
          setShapefiles([]);
        }
      },
      (error) => {
        if (error.response?.data) {
          setErrorMessage(error.response?.data);
        } else {
          setErrorMessage("An error occurred while fetching shapefiles.");
        }
      }
    );
  }, [selectedProject]);

  function toTableDataSource(data: ExploitableReportT) {
    if (!data) {
      return [];
    }
    return data.rows.map((row, i) => {
      const item = {
        key: i,
        originalArea: toUnit(row.originalAreaM2, areaUnit),
        exploitedArea: toUnit(row.intersectedAreaM2, areaUnit),
        originalGeometry: JSON.parse(row.originalGeometry),
        exploitedGeometry: JSON.parse(row.intersectedGeometry),
        spacing: wellSpacing
      };
      item["unexploitedArea"] = item.originalArea - item.exploitedArea;
      item["unexploitedAreaPercent"] = Math.round(
        ((item.originalArea - item.exploitedArea) / item.originalArea) * 100
      );
      let j = 1;
      for (const col of row.columns) {
        item[`col${j++}`] = col.value;
      }
      return item;
    });
  }

  function toColumns(data: ExploitableReportT) {
    const columns = [];
    if (!data) {
      return columns;
    }
    for (const row of data.rows) {
      let i = 1;
      for (const col of row.columns) {
        const header = col.header;
        if (columns.length < i) {
          columns.push({
            header: header,
            accessorKey: `col${i}`,
            id: `col${i}`
          });
        } else {
          const existing = columns[i - 1];
          if (existing.header?.toString().indexOf(header) < 0) {
            existing.header = existing.header + "/" + header;
          }
        }
        i++;
      }
    }
    columns.push({
      header: `Total Area (${areaUnit})`,
      accessorKey: "originalArea",
      id: "Original Area",
      width: 120
    });
    columns.push({
      header: `Exploitable Spacing (m)`,
      accessorKey: "spacing",
      id: "Exploitable Spacing",
      width: 120
    });
    columns.push({
      header: `Exploited Area (${areaUnit})`,
      accessorKey: "exploitedArea",
      id: "Exploited Area",
      width: 120
    });
    columns.push({
      header: `Remaining Undeveloped Area (${areaUnit})`,
      accessorKey: "unexploitedArea",
      id: "Unexploited Area",
      width: 120
    });
    columns.push({
      header: `Undeveloped Area (%)`,
      accessorKey: "unexploitedAreaPercent",
      id: "Unexploited Area Percent",
      width: 120
    });
    for (const col of columns) {
      if (col.header?.toString().length > 40) {
        col.header = col.header.toString().substring(0, 40);
      }
    }
    return columns;
  }

  function copyTableToClipboard() {
    if (!navigator.clipboard) {
      toast.error("Clipboard copy is not supported by the browser.");
    }
    if (!tableRef.current) {
      toast.error("Table is not available.");
    }
    // Clone the table
    const clonedTable = tableRef.current.cloneNode(true) as HTMLTableElement;
    // Get all rows from the cloned table
    const rows = clonedTable.querySelectorAll("tr");

    // For each row, remove the first cell
    rows.forEach((row) => {
      const firstCell = row.querySelector("td, th");
      if (firstCell) {
        row.removeChild(firstCell);
      }
    });

    const spreadSheetRow = new Blob([clonedTable.outerHTML], {
      type: "text/html"
    });
    navigator.clipboard
      .write([new ClipboardItem({ "text/html": spreadSheetRow })])
      .then(() => {
        setCopiedToClipboard(true);
        setTimeout(() => {
          setCopiedToClipboard(false);
        }, 2000);
      })
      .catch(() => setCopiedToClipboard(false));
  }

  function goToRow(
    row: Row<{
      key: number;
      originalArea: number;
      exploitedArea: number;
      originalGeometry: Geometry;
      exploitedGeometry: Geometry;
      spacing: number;
    }>
  ): void {
    const geometries = [row.original.originalGeometry, row.original.exploitedGeometry];
    dispatch(setExploitableArea(geometries));
  }

  return (
    <RootContainer>
      <Typography.Title level={5}>Exploitable Report</Typography.Title>
      <Toolbar>
        <TreeSelect
          treeLine
          popupClassName="shapefile-tree-select"
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          placeholder="Select a Folder"
          treeData={shapefiles}
          value={selectedFolder}
          onChange={setSelectedFolder}
        />

        <StyledButtonGroup>
          <Button
            type="primary"
            disabled={!selectedFolder}
            loading={isFetching || isLoading}
            onClick={() => refetch()}>
            {isFetching || isLoading ? "Calculating" : "Calculate"}
          </Button>
          {(isLoading || isFetching) && (
            <Button
              onClick={() => {
                cancelTokenSourceRef.current.cancel();
                // eslint-disable-next-line import/no-named-as-default-member
                cancelTokenSourceRef.current = axios.CancelToken.source();
              }}>
              Cancel
            </Button>
          )}
        </StyledButtonGroup>

        <Popover
          trigger={"click"}
          content={
            <Card title={"Report Settings"}>
              <SettingsWrapper>
                <CardRow>
                  <LabelText>Spacing</LabelText>
                  <InputNumber
                    value={wellSpacing}
                    step={10}
                    onChange={(num) => setWellSpacing(num)}
                    placeholder="Spacing"
                  />
                </CardRow>
                <CardRow>
                  <LabelText>Units</LabelText>
                  <Select
                    value={areaUnit}
                    onChange={setAreaUnit}
                    options={[
                      { label: "m2", value: "m2" },
                      { label: "ha", value: "ha" },
                      { label: "Section", value: "section" }
                    ]}
                  />
                </CardRow>
              </SettingsWrapper>
            </Card>
          }>
          <SettingsButton icon={<Icon path={mdiCog} size={1.2} />} />
        </Popover>

        <CopyWrapper>
          <Popover
            open={copiedToClipboard}
            trigger="click"
            content={
              <div>
                <Icon path={mdiCheck} size={1} /> Copied to clipboard.
              </div>
            }>
            <Button onClick={copyTableToClipboard}>
              <FileCopyOutlined /> Copy
            </Button>
          </Popover>
        </CopyWrapper>
      </Toolbar>
      <Wrapper>
        {isError || errorMessage ? (
          <Alert message={"An error occurred"} type="error" />
        ) : (
          <table ref={tableRef} width="100%">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  <th className="check" />
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  <td className="check">
                    <Radio
                      checked={checkedRow == row.id}
                      onChange={(evt) => {
                        const checked = evt.target.checked;
                        setCheckedRow(row.id);
                        if (checked) {
                          goToRow(row);
                        }
                      }}
                    />
                  </td>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Wrapper>
    </RootContainer>
  );
}

const StyledButtonGroup = styled(ButtonGroup)`
  .ant-btn {
    border-radius: 0;
  }

  .ant-btn:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .ant-btn:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
const Toolbar = styled.div`
  width: 100%;
  min-height: 40px;
  overflow-x: auto;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  .ant-tree-select {
    min-width: 250px;
  }
`;

const CardRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px 10px;

  .ant-select {
    min-width: 100px;
  }

  .ant-input-number {
    min-width: 100px;
  }
`;

const SettingsButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;

  h5 {
    padding: 5px 10px;
    margin: 0;
  }
`;

const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 200px;
  padding: 0;
`;

const LabelText = styled(Text)`
  min-width: 80px;
  text-align: left;
  display: inline-block;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;

  thead {
    background: white;
    position: sticky;
    top: 0;
    z-index: 10;

    th {
      &.check {
        min-width: 30px;
      }

      min-width: 80px;
      padding: 10px;
      text-align: left;
    }

    .units {
      th {
        padding: 0;
        margin: 0;
        border-bottom: 1px solid black;
        position: sticky;
        background: white;
      }
    }

    .heading2 {
      th {
        color: rgb(35, 49, 87);
        background: white;
        padding: 0;
        margin: 0;
        max-height: 80px;
        height: 80px;
      }
    }

    .heading1 {
      th {
        background: white;
        padding: 0;
        margin: 0;
      }
    }
  }

  td {
    min-width: 120px;
    background: white;
    margin: 0;
    padding: 5px 10px;
    max-height: 25px;

    &.check {
      min-width: 30px;
    }
  }
`;
const CopyWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  .ant-btn {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }
`;

function toUnit(m2: number, areaUnit: AreaUnit) {
  if (areaUnit === "m2") {
    return m2;
  }
  if (areaUnit == "ha") {
    return Math.floor(m2 * 0.0001);
  }
  if (areaUnit == "section") {
    return Math.round(m2 / SquareSection);
  }
}
